import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./MeasurementModal.styles.scss"; // Import the new styles
import { useStore } from "../../Context/StoreContext";
import Button from "../Button/Button";

const MeasurementModal = ({ isOpen, onClose, product }) => {
  const boxCost = product.data.attributes.price;
  const price_unit = product.data.attributes.price_unit;
  const minimum_quantity = product?.data?.attributes?.minimum_quantity;
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [results, setResults] = useState(null);
  const navigate = useNavigate();
  const [added, setAdded] = useState(false);
  const { addToCart } = useStore();
  useEffect(() => {
    const lengthValue = parseFloat(length);
    const widthValue = parseFloat(width);

    if (
      !isNaN(lengthValue) &&
      !isNaN(widthValue) &&
      lengthValue > 0 &&
      widthValue > 0
    ) {
      const area = lengthValue * widthValue;
      const areaWithBuffer = area * 1.1; // Adding 10% for cutting
      const coverageUnit = product.data.attributes.coverage_square_meters; // Coverage per box in square meters
      const boxesNeeded = Math.ceil(areaWithBuffer / coverageUnit);
      const totalCost = boxesNeeded * boxCost;

      setResults({
        area: area.toFixed(2),
        areaWithBuffer: areaWithBuffer.toFixed(2),
        boxesNeeded,
        totalCost: totalCost.toFixed(2),
      });
    } else {
      setResults(null);
    }
  }, [length, width, boxCost]);

  useEffect(() => {
    setLength("");
    setWidth("");
  }, [product]);
  const handleAddToCart = () => {
    setAdded(true);
    addToCart(product.data, results.boxesNeeded);
  };

  const handleCheckout = () => {
    navigate("/cart");
  };
  return isOpen ? (
    <div className="input-group-more">
      <button className="close-button" onClick={onClose}>
        ×
      </button>
      <div className="modal-content">
        <h2>Calculate Measurements</h2>
        <div className="inputs-container">
          <div className="input-group">
            <label className="input-label">Length (m)</label>
            <input
              type="number"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              placeholder="Enter length"
            />
          </div>
          <div className="input-group">
            <label className="input-label">Width (m)</label>
            <input
              type="number"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              placeholder="Enter width"
            />
          </div>
        </div>
        {results && (
          <>
            <div className="summary">
              <h3>Summary</h3>
              <p>Area Measured: {results.area} m²</p>
              <p>Area with 10% for cutting: {results.areaWithBuffer} m²</p>
              <p>
                Number of {price_unit}(s) needed: {results.boxesNeeded}{" "}
                (including 10% extra for cutting)
              </p>
              <p>Total cost: R{results.totalCost}</p>
            </div>
            <Button
              primary
              disabled={results.boxesNeeded < minimum_quantity}
              onClick={added ? handleCheckout : handleAddToCart}
            >
              {added
                ? "Checkout"
                : `Add ${results.boxesNeeded} ${price_unit}(s) to Cart`}
            </Button>
            {results.boxesNeeded < minimum_quantity && (
              <p className="warning">
                Minimum quantity is{" "}
                {product?.data?.attributes?.minimum_quantity}{" "}
                {product?.data?.attributes?.price_unit}
              </p>
            )}
            <Button
              black
              onClick={() => {
                navigate(-1);
              }}
            >
              Continue Shopping
            </Button>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default MeasurementModal;
