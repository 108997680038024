import React from "react";
import "./TotalButton.styles.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { useStore } from "../../Context/StoreContext";

const TotalButton = ({
  back,
  children,
  cart,
  payButton,
  disable,
  buttonType,
}) => {
  const { cartTotal } = useStore();

  return (
    <div className="totalButton">
      {back && (
        <div className="totalButton__back">
          <Link to="/products">
            {" "}
            <Button minimal>
              <img src="assets/svg/chevron-left.svg" />
              Continue Shopping
            </Button>
          </Link>
        </div>
      )}
      <div className="totalButton__forward">
        <h3>Total : R {cartTotal.toFixed(2)}</h3>

        {buttonType ? (
          <Button primary disable={disable} buttonType={buttonType}>
            {children}
          </Button>
        ) : (
          <Link to="/delivery">
            <Button primary disable={disable} buttonType={buttonType}>
              {children}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default TotalButton;
