import React from "react";
import Button from "../Button/Button";
import "./ProductsList.styles.scss";
import ProductItem from "../ProductItem/ProductItem";
import { useStore } from "../.././Context/StoreContext";
import { Link } from "react-router-dom";
const ProductsList = ({
  title = "",
  moreButton = false,
  limit = false,
  products,
  row,
}) => {
  return (
    <div className="products">
      <div>
        {title && (
          <div className="products__heading">
            <h3>{title}</h3>
            {moreButton && (
              <Link to="/sale">
                <Button medium outline>
                  See All{" "}
                  <img src="assets/svg/right-chev.svg" alt="right chev" />
                </Button>
              </Link>
            )}
          </div>
        )}
        <div className="products__collection">
          {products &&
            limit === false &&
            products?.data?.map((product) => {
              return (
                <ProductItem product={product} key={product.id} row={row} />
              );
            })}
          {products &&
            limit === true &&
            products?.data?.slice(0, 4).map((product) => {
              return (
                <ProductItem product={product} key={product.id} row={row} />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
