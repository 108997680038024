import React, { useEffect, useState, useRef } from "react";
import "./Search.styles.scss";
import FormInput from "../FormInput/FormInput";
import SearchItem from "../SearchItem/SearchItem";
import axios from "axios";

const Search = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchParams, setSearchParams] = useState({
    query: "",
    selectedCategory: "",
  });
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchContainerRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/categories`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
            },
          }
        );
        setCategories(response.data.data);
      } catch (error) {
        setError("Error fetching categories");
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const { query, selectedCategory } = searchParams;
      if (query.length >= 3) {
        setIsLoading(true);
        try {
          const productsResponse = await axios.get(
            `${process.env.REACT_APP_STRAPI_URL}/products?populate=*`,
            {
              params: {
                "filters[$or][1][description][$contains]": query,
                "filters[$or][0][name][$contains]": query,
                ...(selectedCategory
                  ? { "filters[category][id][$eq]": selectedCategory }
                  : {}),
              },
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
              },
            }
          );
          setProducts(productsResponse.data.data);
          setIsLoading(false);
        } catch (error) {
          setError("Error fetching products");
          setIsLoading(false);
        }
      } else {
        setProducts([]);
      }
    };
    fetchProducts();
  }, [searchParams]);

  const handleSelectChange = (e) => {
    setSearchParams({
      ...searchParams,
      selectedCategory: e.target.value,
    });
  };

  const clearQuery = () => {
    setSearchParams({
      query: "",
      selectedCategory: "",
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickItem() {
    setIsOpen(false);
    setSearchParams({
      query: "",
      selectedCategory: "",
    });
  }

  return (
    <div className="search">
      <div ref={searchContainerRef} className="search__input-container">
        <div className={`search-inputs ${isOpen ? "active" : ""}`}>
          <FormInput
            type="search"
            className="search-input"
            placeholder="Search for products"
            value={searchParams.query}
            onChange={(e) =>
              setSearchParams({ ...searchParams, query: e.target.value })
            }
          />

          <select
            value={searchParams.selectedCategory}
            name="categories"
            id="categories"
            onChange={handleSelectChange}
          >
            <option value="">Select Category</option>
            {categories.map((item) => (
              <option value={item.id} key={item.id}>
                {item.attributes.title}
              </option>
            ))}
          </select>
          {searchParams.query && (
            <div className="clear-button" onClick={clearQuery}>
              &#x2715;
            </div>
          )}
        </div>
        {!error && searchParams.query.length >= 3 && isOpen && (
          <div className="searchItem__container">
            {products.map((item) => {
              return (
                <SearchItem
                  item={item}
                  isLoading={isLoading}
                  onClick={handleClickItem}
                />
              );
            })}
            {products.length <= 0 && <p>No Results</p>}
          </div>
        )}

        {!searchParams.query && (
          <svg
            className="search-icon"
            width="32"
            height="33"
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsOpen(!isOpen)}
          >
            <path
              d="M18.5404 19.0815L25.4797 26M21.4797 12C21.4797 17.5228 17.0025 22 11.4797 22C5.95688 22 1.47974 17.5228 1.47974 12C1.47974 6.47715 5.95688 2 11.4797 2C17.0025 2 21.4797 6.47715 21.4797 12Z"
              strokeWidth="2.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Search;
