import React from "react";
import "./TermsPage.styles.scss";

const TermsPage = () => {
  return (
    <section className="termsPage">
      <div className="container">
        <h1>Terms & Conditions</h1>
        <ul>
          <li>
            Goods must be checked & signed for on delivery, under no
            circumstances will Kaleidorock accept any claims once tiles have
            been delivered or (clients collecting) means left our warehouse.
          </li>
          <li>
            Purchasers must allow for a 5% breakage allowance on all natural
            stone & tiles.
          </li>
          <li>
            Kaleidorock does not accept goods returns. If the goods must be
            re-delivered for any reason, it will be for the purchaser’s account.
          </li>
          <li>
            No claims whatsoever will be entertained for product after
            installation. Dry lay the product to ensure satisfaction.
          </li>
          <li>
            {" "}
            All cancellations or return are subjected to a 15% handling fee.
          </li>
          <li>All goods remain the property of Kaleidorock until settled</li>
        </ul>
        <div className="termsPage__contact">
          <h3>TEL: 084-899-3742 </h3>
          <h3>EMAIL: INFO@KALEIDOROCK.CO.ZA</h3>
        </div>
      </div>
    </section>
  );
};

export default TermsPage;
