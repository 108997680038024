import React from "react";
import "./SearchItem.styles.scss";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";

const SearchItem = ({ item, isLoading, onClick }) => {
  return (
    <div className="searchItem" onClick={onClick}>
      {
        <Link
          to={`product/${item.attributes.name}/${item.id}`}
          className="searchItem__link"
          key={item.id}
        >
          <div className="searchItem-content">
            <img
              src={
                item.attributes.api_primary_image
                  ? item.attributes.api_primary_image
                  : process.env.REACT_APP_UPLOAD_URL +
                    item.attributes.primary_image.data.attributes.url
              }
            />
            <div className="searchItem-description">
              <p>{item.attributes.name}</p>
            </div>
          </div>
        </Link>
      }
    </div>
  );
};

export default SearchItem;
