import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useStore } from "../../Context/StoreContext";
import { makeRequest } from "../../hooks/makeRequest";
import useFetch from "../../hooks/useFetch";
import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import TelephoneInput from "../TelephoneInput/TelephoneInput";
import "./Account.styles.scss";
import Loader from "../Loader/Loader";

const Account = () => {
  const { user, handleUserLogin } = useStore();

  const { data, loading, error } = useFetch(`/users/${user?.id}`);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    street_name: "",
    apartment_suite_unit: "",
    town_City: "",
    province: "",
    post_code: "",
    country: "",
    password: "",
    confirm_password: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        email: data?.email || "",
        phone_number: data?.phone_number || "",
        company_name: data?.company_name || "",
        street_name: data?.street_name || "",
        apartment_suite_unit: data?.apartment_suite_unit || "",
        town_City: data?.town_City || "",
        province: data?.province || "",
        post_code: data?.post_code || "",
        country: data?.country || "",
        password: "",
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await makeRequest.put(`/users/${user?.id}`, formData);
      toast.success("Account details updated successfully!");

      handleUserLogin(response.data);
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error loading user.data.id data</div>;

  return (
    <div className="account__form">
      <h3>Account Details</h3>
      <form onSubmit={handleSubmit}>
        <div className="account__box">
          <FormInput
            label="First Name"
            half
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
          />
          <FormInput
            label="Last Name"
            half
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
          />
          <FormInput
            label="Email"
            half
            name="email"
            value={formData.email}
            onChange={handleChange}
          />

          <TelephoneInput
            label="Phone Number"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            className="PhoneInputInput"
          />
        </div>
        <div className="account__box">
          <h3>Account</h3>

          <FormInput
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div className="account__box">
          <FormInput
            label="Company Name"
            type="text"
            name="company_name"
            value={formData.company_name}
            onChange={handleChange}
          />
          <FormInput
            label="Street Address"
            type="text"
            name="street_name"
            value={formData.street_name}
            onChange={handleChange}
          />
          <FormInput
            label="apartment_suite_unit, suite, unit, etc. (optional)"
            type="text"
            name="apartment_suite_unit"
            value={formData.apartment_suite_unit}
            onChange={handleChange}
          />
          <FormInput
            label="Town / town_City"
            type="text"
            name="town_City"
            value={formData.town_City}
            onChange={handleChange}
          />
          <FormInput
            label="Province"
            type="text"
            name="province"
            value={formData.province}
            onChange={handleChange}
          />
          <FormInput
            label="Post Code"
            type="number"
            name="post_code"
            value={formData.post_code}
            onChange={handleChange}
          />
          <FormInput
            label="Country"
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
          />
        </div>
        <Button primary type="submit">
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default Account;
