import React, { useState } from "react";
import "./NavbarCart.styles.scss";
import { useStore } from "../../Context/StoreContext";
import MiniCart from "../MiniCart/MiniCart";
import { Link } from "react-router-dom";

const NavbarCart = () => {
  const [isCartPreviewVisible, setIsCartPreviewVisible] = useState(false);
  const { cart } = useStore();
  return (
    <div
      onMouseEnter={() => setIsCartPreviewVisible(true)}
      onMouseLeave={() => setIsCartPreviewVisible(false)}
    >
      <div className="navbar-cart">
        <svg
          width="32"
          height="50"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.87951 4.16623H25.4802L22.8134 13.4997H7.31545M24.1468 18.8332H8.14652L5.47981 1.49951H1.47974M9.47988 24.1666C9.47988 24.903 8.88291 25.4999 8.14652 25.4999C7.41013 25.4999 6.81316 24.903 6.81316 24.1666C6.81316 23.4302 7.41013 22.8332 8.14652 22.8332C8.88291 22.8332 9.47988 23.4302 9.47988 24.1666ZM24.1468 24.1666C24.1468 24.903 23.5499 25.4999 22.8134 25.4999C22.077 25.4999 21.4801 24.903 21.4801 24.1666C21.4801 23.4302 22.077 22.8332 22.8134 22.8332C23.5499 22.8332 24.1468 23.4302 24.1468 24.1666Z"
            strokeWidth="2.66671"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span>{cart.length ? cart.length : 0}</span>
      </div>
      <div
        className={`navbar-cart__preview ${
          isCartPreviewVisible ? "visible" : ""
        }`}
      >
        <MiniCart cart={cart} />
      </div>
    </div>
  );
};

export default NavbarCart;
