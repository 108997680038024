import React from "react";
import "./CaringForStone.styles.scss";

const CaringForStone = () => {
  return (
    <>
      <section className="caringForStone">
        <div className="container">
          <div className="text-box container__left">
            <h1>Caring For Stone</h1>
            <img src="assets/images/caring-for-stone.png" />
            <p>
              Natural Stone Is An Investment That Will Give You Many Years Of
              Beautiful Service. Stone Is A Natural Product And Simple Care And
              Maintenance Will Keep It Looking Beautiful. These Are
              Recommendations From The Marble Institute Of America.
            </p>
          </div>
          <div className="container__right">
            <div className="text-box">
              <h3>PRECAUTIONS</h3>
              <p>
                Use coasters under all glasses, particularly those containing
                alcohol or citrus juices. Many common foods and drinks contain
                acids that will etch or dull the stone surface.
              </p>
              <p>
                Use trivets or placemats under china, ceramics, silver or other
                objects that can scratch the surface.
              </p>
            </div>
            <div className="text-box">
              <h3>ALL STONE SURFACES</h3>
              <p>
                Clean stone surfaces with neutral cleaner, stone soap (available
                at hardware stores or from your stone dealer) or a mild liquid
                dishwashing detergent and warm water. Use a clean rag mop on
                floors and a soft cloth for other surfaces for best results. Too
                much cleaner or soap may leave a film and cause streaks.
              </p>
              <p>
                Do not use products that contain lemon, vinegar or other acids
                on marble or limestone. Rinse the surface thoroughly after
                washing with the soap solution and dry with a soft cloth. Change
                the rinse water frequently. Do not use scouring powders or
                creams; these products contain abrasives that may scratch the
                surface.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="caringForStone grey">
        <div className="container">
          <div className="text-box container__left">
            <img src="assets/images/caring-for-stone-2.png" />
          </div>
          <div className="container__right">
            <div className="text-box">
              <h3>FLOOR SURFACES</h3>
              <p>
                Dust interior floors frequently using a clean, non-treated dry
                dust-mop. Sand, dirt and grit do the most damage to natural
                stone surfaces due to their abrasiveness. Mats or area rugs
                inside and outside an entrance will help to minimize the sand,
                dirt and grit that will scratch the stone floor.
              </p>
              <p>
                Be sure that the underside of the mat or rug is a non-slip
                surface. Normally, it will take a person about eight steps on a
                floor surface to remove sand or dirt from the bottom of their
                shoes. Do not use vacuum cleaners that are worn as attachments
                or the wheels may scratch the surface.
              </p>
              <p>
                Be sure that the underside of the mat or rug is a non-slip
                surface. Normally, it will take a person about eight steps on a
                floor surface to remove sand or dirt from the bottom of their
                shoes. Do not use vacuum cleaners that are worn as attachments
                or the wheels may scratch the surface.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="caringForStone">
        <div className="container">
          <div className="text-box container__left">
            <div className="text-box">
              <h3>FOOD PREPARATION AREAS</h3>
              <p>
                In food preparation areas, the stone may need to have
                penetrating sealer applied. Check with your installer for
                recommendations.
              </p>
              <p>
                If a sealer is applied, be sure that it is non-toxic and safe
                for use on food preparation surfaces. If there is a question,
                check with the sealer manufacturer.
              </p>
            </div>
          </div>
          <div className="container__right">
            <div className="text-box">
              <h3>OUTDOOR POOL & PATIO AREAS</h3>
              <p>
                In outdoor pool, patio or hot tub areas flush with clear water
                and use a mild bleach solution to remove algae or moss.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="caringForStone">
        <div className="container">
          <div className="text-box container__left">
            <img src="assets/images/caring-for-stone-1.png" />
          </div>
          <div className="container__right">
            <div className="text-box">
              <h3>DO’S & DON’TS</h3>
              <ul>
                <li>
                  <img src="assets/svg/do.svg" />
                  Do dust mop floors frequently
                </li>
                <li>
                  <img src="assets/svg/do.svg" />
                  Do clean surfaces with mild detergent or stone soap
                </li>
                <li>
                  <img src="assets/svg/do.svg" />
                  Do thoroughly rinse and dry the surface after washing
                </li>
                <li>
                  <img src="assets/svg/do.svg" />
                  Do blot up spills immediately
                </li>
                <li>
                  <img src="assets/svg/do.svg" />
                  Do protect floor surfaces with non-slip mats or areas rugs and
                  countertop surfaces with coasters, trivets or placemats
                </li>
                <li>
                  <img src="assets/svg/donts.svg" />
                  Don’t use cleaners that contain acid such as bathroom
                  cleaners, grout cleaners of tub & tile cleaners
                </li>
                <li>
                  <img src="assets/svg/donts.svg" />
                  Don’t use vinegar, lemon juice or other cleaners containing
                  acids on marble, limestone, travertine or onyx surfaces
                </li>
                <li>
                  <img src="assets/svg/donts.svg" />
                  Don’t use abrasive cleaners such as dry or soft cleansers{" "}
                </li>
                <li>
                  <img src="assets/svg/donts.svg" />
                  Don’t mix bleach and ammonia – this combination creates a
                  toxic and lethal gas
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CaringForStone;
