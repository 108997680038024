import React, { useEffect } from "react";
import Button from "../../components/Button/Button";
import Checkout from "../../components/Checkout/Checkout";
import OrderSummary from "../../components/OrderSummary/OrderSummary";
import "./CheckoutPage.styles.scss";
import { Link } from "react-router-dom";
import { useStore } from "../../Context/StoreContext";

const CheckoutPage = () => {
  const { user } = useStore();

  return (
    <section className="checkoutPage">
      <div className="container">
        <h1>Checkout</h1>
        <div className="checkoutPage__content">
          <div className="checkoutPage__form">
            {!user && (
              <div className="checkoutPage__login">
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            )}
            <h3>Billing details</h3>
            <Checkout />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CheckoutPage;
