import React, { useEffect, useState } from "react";
import ProductsList from "../../components/ProductsList/ProductsList";
import Categories from "../../components/Categories/Categories";
import HeroSlider from "../../components/HeroSlider/HeroSlider";
import "./HomePage.styles.scss";
import Carousel from "../../components/Carousel/Carousel";
import { useStore } from "../../Context/StoreContext";
import axios from "axios";
import useFetch from "../../hooks/useFetch";
import Loader from "../../components/Loader/Loader";

const HomePage = () => {
  const {
    data: saleProducts,
    loading: loading1,
    error: error1,
  } = useFetch(`/products?populate=*&filters[is_on_sale][$eq]=yes`);
  const { data: featuredProducts, loading: loading2 } = useFetch(
    `/products?populate=*&filters[product_type][$eq]=featured`
  );

  return (
    <div className="homePage">
      <Carousel>
        <HeroSlider
          title="cladding"
          imageSrc="assets/images/cladding-slider.webp"
        />
        <HeroSlider
          title="bricks"
          imageSrc="assets/images/bricks-slider.webp"
        />
        <HeroSlider title="tiles" imageSrc="assets/images/tiles-slider.webp" />
      </Carousel>
      {loading1 ? (
        <Loader />
      ) : (
        <section>
          <div className="container">
            <ProductsList
              title="sales"
              moreButton
              limit
              products={saleProducts}
            />
          </div>
        </section>
      )}

      <Categories />
      {loading2 ? (
        <Loader />
      ) : (
        <section>
          <div className="container">
            <ProductsList
              title="Featured Products"
              limit
              products={featuredProducts}
            />
          </div>
        </section>
      )}
    </div>
  );
};

export default HomePage;
