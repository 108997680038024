import React, { useState } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { Link } from "react-router-dom";
import { makeRequest } from "../../hooks/makeRequest";
import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import Testimonials from "../Testimonials/Testimonials";
import "./Login.styles.scss";
import { useStore } from "../../Context/StoreContext";
import Loader from "../Loader/Loader";

const Login = () => {
  const initialUserInfo = {
    identifier: "",
    password: "",
  };
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const { handleUserLogin } = useStore();
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((curr) => ({
      ...curr,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await makeRequest.post(`/auth/local`, userInfo);

      setLoading(false);
      handleUserLogin(res.data.user);
      localStorage.setItem("user", JSON.stringify(res.data.user));
      toast.success("you have successfully logged in");
      window.location.href = "/my-account/account-details";
    } catch (error) {
      setLoading(false);
      const errorMessages = error.response?.data?.error?.details?.errors;

      if (errorMessages?.length > 1) {
        errorMessages.forEach((item) => {
          const errorMessage =
            item.message === "identifier is a required field"
              ? "Email is a required field"
              : item.message;
          toast.error(errorMessage);
        });
      } else {
        const mainErrorMessage = error.response.data.error.message;
        const displayMessage =
          mainErrorMessage === "identifier is a required field"
            ? "Email is a required field"
            : mainErrorMessage === "Invalid identifier or password"
            ? "Invalid email or password"
            : mainErrorMessage;

        toast.error(displayMessage);
      }
    }
  };

  return (
    <div className="login">
      <div className="container">
        <div className="login__form">
          <h1>Log In</h1>
          <form onSubmit={handleLogin}>
            <FormInput
              name="identifier"
              label="Email"
              type="text"
              onChange={handleChange}
              value={userInfo.identifier}
            />
            <FormInput
              name="password"
              label="Password"
              type="password"
              onChange={handleChange}
              value={userInfo.password}
            />
            <Button primary type="submit">
              Login {loading && <Loader small />}
            </Button>
            {/* <div className="divider"></div>
            <Button google>
              <img src="assets/svg/google-color.svg" alt="Google logo" />
              Log In With Google
            </Button> */}
            <p>
              Don't have an account?{" "}
              <Link to="/signup">
                <span>Sign Up</span>
              </Link>
            </p>
          </form>
        </div>
        <div className="login__testimonials">
          <Testimonials />
        </div>
      </div>
    </div>
  );
};

export default Login;
