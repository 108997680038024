import React from "react";
import TotalButton from "../TotalButton/TotalButton";
import MiniCartItem from "../MiniCartItem/MiniCartItem";
import "./OrderSummary.styles.scss";
import { useStore } from "../../Context/StoreContext";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const OrderSummary = ({ buttonType }) => {
  const { cart } = useStore();

  return (
    <div className="orderSummary">
      <h3 className="orderSummary__heading">Order Summary</h3>
      <div className="orderSummary__items">
        {cart.length <= 0 && (
          <div className="orderSummary__items-empty">
            <h3>Your cart is empty</h3>
            <Link to="/products">
              <Button small minimal>
                Start Shopping{" "}
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 13L7.5 7L1.5 1"
                    stroke="#F28B0B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </Link>
          </div>
        )}
        {cart &&
          cart?.map((item) => {
            return <MiniCartItem item={item} key={item.id} />;
          })}
      </div>
      <div className="orderSummary__footer">
        <TotalButton
          cart={cart}
          disable={cart.length === 0}
          buttonType={buttonType}
        >
          PayNow
        </TotalButton>
      </div>
    </div>
  );
};

export default OrderSummary;
