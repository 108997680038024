import React, { useState } from "react";
import toast from "react-hot-toast";
import { makeRequest } from "../../hooks/makeRequest";
import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import Testimonials from "../Testimonials/Testimonials";
import "./Signup.styles.scss";
import { useStore } from "../../Context/StoreContext";

const SignUp = () => {
  const { handleUserLogin } = useStore();
  const initialUser = {
    email: "",
    password: "",
    confirm_password: "",
  };
  const [user, setUser] = useState(initialUser);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((curr) => ({
      ...curr,
      [name]: value,
      username: name === "email" ? value : curr.username, // Ensure username updates with email
    }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (user.password !== user.confirm_password) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const res = await makeRequest.post(`/auth/local/register`, user);
      setUser(res.data.user);
      handleUserLogin(res.data.user);
      toast.success("Registration successful!");
      localStorage.setItem("user", JSON.stringify(res.data.user));
      window.location.href = "/my-account/account-details";
    } catch (error) {
      const errorMessages = error.response?.data?.error?.details?.errors;
      if (errorMessages) {
        errorMessages.forEach(({ message }) => {
          const errorMessage =
            message === "identifier is a required field"
              ? "Email is a required field"
              : message;
          toast.error(errorMessage);
        });
      } else {
        const mainErrorMessage =
          error.response?.data?.error?.message || "An error occurred";
        const displayMessage =
          mainErrorMessage === "identifier is a required field"
            ? "Email is a required field"
            : mainErrorMessage === "Invalid identifier or password"
            ? "Invalid email or password"
            : mainErrorMessage;
        toast.error(displayMessage);
      }
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <div className="signup__form">
          <h1>Sign Up</h1>
          <form onSubmit={handleSignUp}>
            <FormInput
              onChange={handleChange}
              value={user.first_name}
              name="first_name"
              label="First Name"
              type="text"
              required
            />
            <FormInput
              onChange={handleChange}
              value={user.last_name}
              name="last_name"
              label="Last Name"
              type="text"
              required
            />

            <FormInput
              onChange={handleChange}
              value={user.email}
              name="email"
              label="Email"
              type="email"
              required
            />
            <FormInput
              onChange={handleChange}
              value={user.password}
              name="password"
              label="Password"
              type="password"
              required
            />
            <FormInput
              onChange={handleChange}
              value={user.confirm_password}
              name="confirm_password"
              label="Confirm Password"
              type="password"
              required
            />
            <Button primary type="submit">
              Sign Up
            </Button>
            {/* <div className="divider"></div>
            <Button google>
              <img src="assets/svg/google-color.svg" alt="Google logo" />
              Sign Up With Google
            </Button> */}
            <p>
              Already have an account? <span>Log In</span>
            </p>
          </form>
        </div>
        <div className="signup__testimonials">
          <Testimonials />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
