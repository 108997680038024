import React from "react";
import "./FormInput.styles.scss";

const FormInput = ({
  label = "",
  placeholder = "",
  primary = false,
  type = "text",
  value,
  small,
  medium,
  large,
  half,
  onChange,
  name,
  className,
  required,
  disabled,
  readOnly,
}) => {
  return (
    <div className={`form-group ${half ? "half" : ""}`}>
      {label && <label className="form-label">{label}</label>}
      {
        <input
          className={`form-input ${primary ? "primary" : ""} ${
            small ? "small" : ""
          } ${medium ? "medium" : ""} ${large ? "large" : ""} ${className}`}
          type={type}
          placeholder={placeholder ? `${placeholder}` : ""}
          value={value}
          onChange={onChange}
          name={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
        />
      }
    </div>
  );
};

export default FormInput;
