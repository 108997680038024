import React from "react";
import "./Submenu.styles.scss";
import { NavLink } from "react-router-dom";

const Submenu = ({ setVisible }) => {
  return (
    <div className="submenu">
      <NavLink
        className="submenu__link"
        to="advantages-of-natural-stone"
        onClick={() => setVisible(false)}
      >
        Advantages of Natural Stone
      </NavLink>
      <NavLink
        to="natural-stone-vs-ceramic-tile"
        className="submenu__link"
        onClick={() => setVisible(false)}
      >
        Natural Stone vs. Ceramic Tile
      </NavLink>
      <NavLink
        to="installation-principles"
        className="submenu__link"
        onClick={() => setVisible(false)}
      >
        Installation Principles
      </NavLink>
      <NavLink
        to="caring-for-stone"
        className="submenu__link"
        onClick={() => setVisible(false)}
      >
        Caring for Stone
      </NavLink>
    </div>
  );
};

export default Submenu;
