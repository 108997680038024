import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./TelephoneInput.styles.scss";

const TelephoneInput = ({
  label,
  value,
  onChange,
  name,
  readOnly,
  required,
  disabled,
}) => {
  const handlePhoneChange = (phoneValue) => {
    onChange({ target: { name, value: phoneValue } });
  };

  return (
    <div className="form-group half">
      <label className="form-label">{label}</label>
      <PhoneInput
        defaultCountry="ZA"
        value={value}
        onChange={handlePhoneChange}
        name={name}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        withCountryCallingCode={false}
        className="form-input"
      />
    </div>
  );
};

export default TelephoneInput;
