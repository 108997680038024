import axios from "axios";
import React, {
  Children,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";

const StoreContext = createContext();
const cartLocalStorage = JSON.parse(localStorage.getItem("cart") || "[]");
const userLocalStorage = JSON.parse(localStorage.getItem("user") || null);
const StoreProvider = ({ children }) => {
  const [productsPageNum, setProductPageNum] = useState(1);
  const [currentProduct, setCurrentProduct] = useState("");
  const [user, setUser] = useState(userLocalStorage);
  const [cart, setCart] = useState(cartLocalStorage);
  const [cartTotal, setCartTotal] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleLoadMoreButton(num) {
    if (productsPageNum >= 1) {
      setProductPageNum(productsPageNum + num);
    }
  }
  function handleUserLogin(user) {
    setUser(user);
  }

  function handleUserLogout() {
    localStorage.removeItem("user");
    setUser(null);

    toast.success("You have successfully logged out");
    window.location.href = "/";
  }
  function addToCart(product, num) {
    const existingCartItem = cart.find(
      (cartItem) => cartItem.id === product.id
    );

    if (existingCartItem) {
      return setCart(
        cart.map((cartItem) =>
          cartItem.id === product.id
            ? { ...cartItem, quantity: cartItem.quantity + num }
            : cartItem
        )
      );
    }
    toast.success(`${product.attributes.name} added to cart`, {
      iconTheme: {
        primary: "#f28b0b",
        secondary: "#FFFAEE",
      },
    });
    return setCart((cart) => [...cart, { ...product, quantity: num }]);
  }

  function handleRemoveCartItem(product) {
    const newCart = cart.filter((item) => item.id !== product.id);
    setCart(newCart);
  }

  function handleCartItemDecrement(product) {
    return setCart(
      cart.map((cartItem) =>
        cartItem.id === product.id && product.quantity > 1
          ? { ...cartItem, quantity: cartItem.quantity - 1 }
          : cartItem
      )
    );
  }
  function handleCartItemIncrement(product) {
    return setCart(
      cart.map((cartItem) =>
        cartItem.id === product.id
          ? { ...cartItem, quantity: cartItem.quantity + 1 }
          : cartItem
      )
    );
  }

  function handleClearCart() {
    setCart([]);
  }

  function getProduct(id) {
    async function fetchProduct() {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_STRAPI_URL}/products/${id}?populate=*`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
            },
          }
        );
        setCurrentProduct(res.data);
      } catch (error) {}
    }

    fetchProduct();
  }

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    setCartTotal(
      cart?.reduce(
        (total, item) =>
          total + item.attributes.price.toFixed(2) * item.quantity,
        0
      )
    );
  }, [cart]);

  return (
    <StoreContext.Provider
      value={{
        currentProduct,
        getProduct,
        isLoading,
        handleLoadMoreButton,
        productsPageNum,
        addToCart,
        cart,
        cartTotal,
        handleRemoveCartItem,
        handleCartItemDecrement,
        handleCartItemIncrement,
        handleClearCart,
        handleUserLogin,
        user,
        handleUserLogout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const context = useContext(StoreContext);
  return context;
}

export { StoreProvider, useStore };
