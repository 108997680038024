import React from "react";
import "./FormTextarea.styles.scss";
const FormTextarea = ({
  label = "",
  placeholder = "",
  value,
  rows = 5,
  onChange,
  name,
}) => {
  return (
    <div className={`form-group`}>
      {label && <label className="form-label">{label}</label>}
      {
        <textarea
          className="form-input"
          placeholder={placeholder ? `${placeholder}` : ""}
          value={value}
          rows={rows}
          onChange={onChange}
          name={name}
        />
      }
    </div>
  );
};
export default FormTextarea;
