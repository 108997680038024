import React from "react";
import "./FooterLinks.styles.scss";
import { Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

const FooterLinks = () => {
  const { data } = useFetch("/categories");

  return (
    <div className="footer__links">
      <div className="footer__links-shop">
        <h3>Shop</h3>
        <ul>
          <Link to="/tiles" className="navbarCat">
            <li>tiles</li>
          </Link>
          <Link to="/bricks" className="navbarCat">
            <li>bricks</li>
          </Link>
          <Link to="/Slabs" className="navbarCat">
            <li>Slabs</li>
          </Link>
          <Link to="/Cladding" className="navbarCat">
            <li>Cladding</li>
          </Link>
          <Link to="/adhesive" className="navbarCat">
            <li>Adhesive</li>
          </Link>
          <Link to="/Paving" className="navbarCat">
            <li>Paving</li>
          </Link>
          <Link to="/bathroom" className="navbarCat">
            <li>Bathroom</li>
          </Link>
          <Link to="/Kitchen" className="navbarCat">
            <li>Kitchen</li>
          </Link>
          <Link to="/sale" className="navbarCat">
            <li>Sale</li>
          </Link>
        </ul>
      </div>
      <div className="footer__links-links">
        <h3>Links</h3>
        <ul>
          <Link to="products">
            <li>Products</li>
          </Link>
          <Link to="contact-us">
            <li>Contact Us</li>
          </Link>
          <Link to="my-account">
            <li>My Account</li>
          </Link>
          <Link to="terms-and-conditions">
            <li>Terms & conditions - Return Policy</li>
          </Link>
        </ul>
      </div>
      <div className="footer__links-contact">
        <h3>Contact Us</h3>
        <ul>
          <li>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 21.0003C15.3463 20.9988 13.7183 20.5901 12.26 19.8103L11.81 19.5603C8.70075 17.8886 6.15169 15.3395 4.48 12.2303L4.23 11.7803C3.42982 10.3137 3.00713 8.67097 3 7.00027V6.33027C2.99958 5.79723 3.21196 5.28607 3.59 4.91027L5.28 3.22027C5.44413 3.05487 5.67581 2.97515 5.90696 3.00453C6.13811 3.03391 6.34248 3.16907 6.46 3.37027L8.71 7.23027C8.93753 7.62316 8.87183 8.12003 8.55 8.44027L6.66 10.3303C6.50304 10.4855 6.46647 10.7253 6.57 10.9203L6.92 11.5803C8.17704 13.9087 10.0893 15.8175 12.42 17.0703L13.08 17.4303C13.275 17.5338 13.5148 17.4972 13.67 17.3403L15.56 15.4503C15.8802 15.1285 16.3771 15.0628 16.77 15.2903L20.63 17.5403C20.8312 17.6578 20.9664 17.8622 20.9957 18.0933C21.0251 18.3245 20.9454 18.5562 20.78 18.7203L19.09 20.4103C18.7142 20.7883 18.203 21.0007 17.67 21.0003H17Z"
                  fill="black"
                />
              </svg>
            </span>
            084-899-3742
          </li>
          <li>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 21.0003C15.3463 20.9988 13.7183 20.5901 12.26 19.8103L11.81 19.5603C8.70075 17.8886 6.15169 15.3395 4.48 12.2303L4.23 11.7803C3.42982 10.3137 3.00713 8.67097 3 7.00027V6.33027C2.99958 5.79723 3.21196 5.28607 3.59 4.91027L5.28 3.22027C5.44413 3.05487 5.67581 2.97515 5.90696 3.00453C6.13811 3.03391 6.34248 3.16907 6.46 3.37027L8.71 7.23027C8.93753 7.62316 8.87183 8.12003 8.55 8.44027L6.66 10.3303C6.50304 10.4855 6.46647 10.7253 6.57 10.9203L6.92 11.5803C8.17704 13.9087 10.0893 15.8175 12.42 17.0703L13.08 17.4303C13.275 17.5338 13.5148 17.4972 13.67 17.3403L15.56 15.4503C15.8802 15.1285 16.3771 15.0628 16.77 15.2903L20.63 17.5403C20.8312 17.6578 20.9664 17.8622 20.9957 18.0933C21.0251 18.3245 20.9454 18.5562 20.78 18.7203L19.09 20.4103C18.7142 20.7883 18.203 21.0007 17.67 21.0003H17Z"
                  fill="black"
                />
              </svg>
            </span>
            084-727-9786
          </li>
          <li>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 4.00049H20C21.1046 4.00049 22 4.89592 22 6.00049V18.0005C22 19.1051 21.1046 20.0005 20 20.0005h3C2.89543 20.0005 2 19.1051 2 18.0005V6.00049C2 4.89592 2.89543 4.00049 4 4.00049ZM13.65 15.4505L20 11.0005V8.90049L12.65 14.0505C12.2591 14.3218 11.7409 14.3218 11.35 14.0505L4 8.90049V11.0005L10.35 15.4505C11.341 16.1432 12.659 16.1432 13.65 15.4505Z"
                  fill="black"
                />
              </svg>
            </span>
            info@kaleidorock.co.za
          </li>
          <li>
            <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 4.00049H20C21.1046 4.00049 22 4.89592 22 6.00049V18.0005C22 19.1051 21.1046 20.0005 20 20.0005h3C2.89543 20.0005 2 19.1051 2 18.0005V6.00049C2 4.89592 2.89543 4.00049 4 4.00049ZM13.65 15.4505L20 11.0005V8.90049L12.65 14.0505C12.2591 14.3218 11.7409 14.3218 11.35 14.0505L4 8.90049V11.0005L10.35 15.4505C11.341 16.1432 12.659 16.1432 13.65 15.4505Z"
                  fill="black"
                />
              </svg>
            </span>
            sales@kaleidorock.co.za
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;
