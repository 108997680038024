import React, { useState } from "react";
import "./ContactPage.styles.scss";
import FormInput from "../../components/FormInput/FormInput";
import FormTextarea from "../../components/FormTextarea/FormTextarea";
import Button from "../../components/Button/Button";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/Loader";

const ContactPage = () => {
  const [form, setForm] = useState({
    email: "",
    text: "",
    tel: "",
    user: "",
  });
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    if (!form.user) {
      toast.error("Name is required");
      return false;
    }

    if (!form.email) {
      toast.error("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      toast.error("Email is invalid");
      return false;
    }

    if (!form.text) {
      toast.error("Message is required");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return; // Stop submission if validation fails
    }

    setLoading(true);

    try {
      const token = await window.grecaptcha.execute(
        "6LdMGx8qAAAAAFvLjaMfU7LJlCHlF9lOD-v8D62l",
        { action: "submit" }
      );
      setRecaptchaToken(token);

      const response = await axios.post(
        "https://kaleidorock.com/email/send-email.php",
        {
          ...form,
          recaptchaToken: token,
        }
      );

      toast.success(response && response.data);
      setForm({
        email: "",
        text: "",
        tel: "",
        user: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while sending the email");
    }
  };

  return (
    <div className="contactPage">
      <div className="contactPage__hero">
        <h1>For Your Convenience We Come To You</h1>
        <p>
          So you can have a feel for the product in the comfort of your own home
          or commercial property
        </p>
      </div>
      <section className="contactPage__form">
        <div className="container">
          <div className="contactPage__form-left">
            <h3>Contact Us</h3>
            <p>Please fill form to contact Us</p>
            <form onSubmit={handleSubmit}>
              <FormInput
                label="Name"
                type="text"
                name="user"
                onChange={handleChange}
                value={form.user}
              />

              <FormInput
                label="Email"
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
              />

              <FormInput
                label="Phone Number"
                type="tel"
                name="tel"
                value={form.tel}
                onChange={handleChange}
              />

              <FormTextarea
                label="Message"
                name="text"
                value={form.text}
                onChange={handleChange}
              />

              <Button buttonType="submit" primary>
                Send Message {loading && <Loader small />}
              </Button>
            </form>
          </div>
          <div className="contactPage__form-left">
            <img src="assets/images/contact.png" alt="Contact" />
          </div>
        </div>
      </section>
      <section className="contactPage__details">
        <div className="container">
          <div className="contactPage__details-item">
            <img src="assets/svg/Email-primary.svg" alt="Email" />
            <h3>Email</h3>
            <div className="content">
              <p>info@kaleidorock.co.za</p>
              <p>sales@kaleidorock.co.za</p>
            </div>
          </div>
          <div className="contactPage__details-item">
            <img src="assets/svg/phone-primary.svg" alt="Phone" />
            <h3>Phone</h3>
            <div className="content">
              <p>084-899-3742</p>
              <p>084-727-9786</p>
            </div>
          </div>
          <div className="contactPage__details-item">
            <img src="assets/svg/whatsapp.svg" alt="Whatsapp" />
            <h3>Whatsapp</h3>
            <div className="content">
              <p>084-899-3742</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
