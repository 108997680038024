import React from "react";
import "./Categories.styles.scss";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";

const Categories = () => {
  const { data, loading, error } = useFetch("/categories");

  if (loading) return <Loader />;
  if (error) return <p>Error loading categories.</p>;

  return (
    <section className="categories">
      <div className="container">
        <div className="categories__firstRow">
          <Link to="bricks">
            <div className="categories__firstRow-left">
              <h3>Bricks</h3>
            </div>
          </Link>
          <div className="categories__firstRow-center">
            <Link to="cladding">
              <div className="categories__firstRow-center-item cladding">
                <h3>Cladding</h3>
              </div>
            </Link>
            <Link to="slabs">
              <div className="categories__firstRow-center-item slabs">
                <h3>Slabs</h3>
              </div>
            </Link>
          </div>
          <Link to="tiles">
            <div className="categories__firstRow-right">
              <h3>Tiles</h3>
            </div>
          </Link>
        </div>
        <div className="categories__secondRow">
          <Link to="bathroom">
            <div className="categories__secondRow-left">
              <h3>Bathroom</h3>
            </div>
          </Link>
          <Link to="paving">
            <div className="categories__secondRow-right">
              <h3>Paving</h3>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Categories;
