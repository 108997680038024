import React from "react";
import "./AccountPage.styles.scss";
import OrderDetails from "../../components/OrderDetails/OrderDetails";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";

const AccountPage = () => {
  return (
    <section className="accountPage">
      <div className="container">
        <div className="accountPage__left">
          <Sidebar />
        </div>
        <div className="accountPage__right">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default AccountPage;
