import React from "react";
import "./OrderDetails.styles.scss";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import CartItem from "../CartItem/CartItem";
import Loader from "../Loader/Loader";
import { useStore } from "../../Context/StoreContext";

const OrderDetails = () => {
  const { id } = useParams();
  const { data, loading, error } = useFetch(
    `/orders?populate[orderedItems][populate]=primary_image,product.primary_image&filters[id][$eq]=${id}`,
    [id]
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading order details: {error.message}</div>;
  }

  const orderData = data?.data && data.data.length > 0 ? data.data[0] : null;

  if (!orderData) {
    return <div>No order details found.</div>;
  }

  return (
    <div className="orderDetails">
      <h3>Order Details</h3>
      <div className="orderDetails__info">
        <div className="orderDetails__info-address">
          <h3>Shipping Address</h3>
          <p>
            {orderData?.attributes?.first_name +
              " " +
              orderData?.attributes?.last_name}
          </p>
          {orderData?.attributes?.company_name && (
            <p>{orderData?.attributes?.company_name}</p>
          )}
          <p>{orderData?.attributes?.street_name}</p>
          {orderData?.attributes?.apartment_suite_unit && (
            <p>{orderData?.attributes?.apartment_suite_unit}</p>
          )}

          <p>{orderData?.attributes?.town_city}</p>
          <p>{orderData?.attributes?.province}</p>
          <p>{orderData?.attributes?.country}</p>
          <p>{orderData?.attributes?.post_code}</p>
        </div>
        <div className="orderDetails__info-status">
          <h3>Order Summary</h3>

          <p>
            Order Status : <span>{orderData?.attributes?.order_status}</span>
          </p>
          <p>
            Order Date :{" "}
            {new Date(orderData?.attributes?.order_date).toLocaleDateString(
              "en-GB"
            )}
          </p>
          <p>Order No : #{orderData?.id}</p>

          <p>Order Total : R {orderData.attributes.order_amount.toFixed(2)}</p>
        </div>
      </div>
      <table className="cart__summary">
        {orderData?.attributes?.orderedItems?.map((item) => {
          return (
            <CartItem
              item={item.product.data}
              orderDetails={true}
              quantity={item.quantity}
            />
          );
        })}
      </table>
      <div className="orderDetails__total">
        <h3>Total R{orderData.attributes.order_amount.toFixed(2)}</h3>
      </div>
    </div>
  );
};

export default OrderDetails;
