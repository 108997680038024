import React, { useState, useEffect, useCallback } from "react";
import "./Products.styles.scss";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Button from "../Button/Button";
import ProductsList from "../ProductsList/ProductsList";
import Loader from "../Loader/Loader";
import categories from "../../categories.json";
import brands from "../../brands.json";

const Products = () => {
  const { category, subcategory } = useParams();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedSubcategories, setSelectedSubcategories] = useState(
    subcategory ? [subcategory] : []
  );
  const [visible, setVisible] = useState(false);
  const [sort, setSort] = useState("asc");
  const cat = category.replace(/\/+$/, "");
  const [selectedCategory, setSelectedCategory] = useState(cat);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState("");

  useEffect(() => {
    setSelectedCategory(cat); // Update selected category when pathname changes
    if (subcategory) {
      setSelectedSubcategories([subcategory]);
    } else {
      setSelectedSubcategories([]);
    }
  }, [pathname, cat, subcategory]);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const subcats = params.get("subcategories");
    const sortOrder = params.get("sort") || "asc";
    if (subcats) {
      setSelectedSubcategories(subcats.split(","));
    }
    setSort(sortOrder);
  }, [search]);

  useEffect(() => {
    if (cat === "products") {
      setFilteredCategories(
        categories.filter((c) => c.title !== "adhesive" && c.title !== "sale")
      );
    } else {
      const categoryData = categories.find((c) => c.title === cat);
      if (categoryData) {
        setFilteredCategories([categoryData]);
      }
    }
  }, [cat]);

  const generateUrl = useCallback(() => {
    let url = `/products?populate=*&pagination[page]=${pageNumber}&sort[price]=${sort}${
      selectedBrands ? `&filters[brand][$eq]=${selectedBrands}` : ""
    }`;
    if (cat === "sale") {
      url = `/products?populate=*&filters[is_on_sale][$eq]=yes&pagination[page]=${pageNumber}&sort[price]=${sort}${
        selectedBrands ? `&filters[brand][$eq]=${selectedBrands}` : ""
      }`;
    } else if (cat === "adhesive") {
      url = `/products?populate=*&filters[category][title][$eq]=${cat}&pagination[page]=${pageNumber}&sort[price]=${sort}${
        selectedBrands ? `&filters[brand][$eq]=${selectedBrands}` : ""
      }`;
    } else if (cat !== "products" && cat !== "adhesive") {
      const subcatFilter = selectedSubcategories
        .map((sub) => `&filters[sub_category][title][$eq]=${sub}`)
        .join("");
      url = `/products?populate=*&filters[category][title][$eq]=${cat}${subcatFilter}&pagination[page]=${pageNumber}&sort[price]=${sort}${
        selectedBrands ? `&filters[brand][$eq]=${selectedBrands}` : ""
      }`;
    }
    return url;
  }, [pageNumber, sort, cat, selectedSubcategories, pathname, selectedBrands]);

  const { data, loading } = useFetch(generateUrl(), [
    search,
    selectedSubcategories,
    pageNumber,
    cat,
    sort,
  ]);

  const totalPages = data?.meta?.pagination?.pageCount || 1;

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (pageNumber > 3) {
        pageNumbers.push(1, 2, "...");
      }
      for (
        let i = Math.max(1, pageNumber - 1);
        i <= Math.min(totalPages, pageNumber + 1);
        i++
      ) {
        pageNumbers.push(i);
      }
      if (pageNumber < totalPages - 2) {
        pageNumbers.push("...", totalPages - 1, totalPages);
      }
    }
    return pageNumbers;
  };

  const handlePageChange = (pageNum) => {
    if (typeof pageNum === "number") {
      setPageNumber(pageNum);
      const params = new URLSearchParams(search);
      params.set("page", pageNum);
      navigate({ search: params.toString() });
      window.scrollTo(0, 0); // Scroll to top
    }
  };

  const handleBrandChange = (brand, isChecked) => {
    setSelectedBrands((selectedBrands) =>
      selectedBrands === brand
        ? setSelectedBrands("")
        : setSelectedBrands(brand)
    );
  };

  const handleSortChange = (order) => {
    setSort(order);
    const params = new URLSearchParams(search);
    params.set("sort", order);
    navigate({ search: params.toString() });
    window.scrollTo(0, 0); // Scroll to top
  };

  const toggleCategory = (title) => {
    setSelectedCategory(title);
  };

  if (loading) return <Loader />;

  return (
    <section className="products pro-page">
      <div className="container">
        <h1>{category}</h1>
        {selectedSubcategories && (
          <p className="subcategory-title">{selectedSubcategories}</p>
        )}
        {data?.data?.length > 0 && (
          <div className="products__meta">
            <p>{data?.meta?.pagination.total} products found</p>
            <p>
              {pageNumber} of {totalPages} pages
            </p>
          </div>
        )}

        <div className="products__list">
          <div className="products__container">
            <div className="products__filters-buttons">
              <p>Filters & Sorting</p>
              <svg
                onClick={() => setVisible(!visible)}
                width="20px"
                height="20px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#000000"
                  d="M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z"
                />
              </svg>
            </div>
            <div className={`products__filters ${visible ? "visible" : ""}`}>
              {(category === "products" ||
                category === "bathroom" ||
                category === "kitchen") && (
                <div className="products__filters-brands">
                  <h3>Brands</h3>
                  <ul>
                    {brands[0].brands.map((brand) => (
                      <li key={brand}>
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              selectedBrands && selectedBrands.includes(brand)
                            }
                            onChange={(e) =>
                              handleBrandChange(brand, e.target.checked)
                            }
                            onClick={() => setVisible(false)}
                          />
                          {brand}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="products__filters-categories">
                <h3>Categories</h3>
                <ul>
                  {loading && <Loader />}
                  {categories
                    .filter(
                      (category) =>
                        category.title !== "adhesive" &&
                        category.title !== "sale"
                    )
                    .map((category) => (
                      <li key={category.title}>
                        <Link
                          to={`/${category.title}`}
                          className="category-link"
                        >
                          <div
                            className={`category-title ${
                              selectedCategory === category.title
                                ? "active"
                                : ""
                            }`}
                            onClick={() => toggleCategory(category.title)}
                          >
                            {category.title}
                          </div>
                        </Link>
                        {selectedCategory === category.title && (
                          <ul>
                            {category.subcategories.map((sub) => (
                              <Link
                                key={sub.title + 20}
                                className={`subcategory-title ${
                                  selectedSubcategories.includes(sub.title)
                                    ? "active"
                                    : ""
                                }`}
                                to={`/${selectedCategory}/${sub.title}`}
                                onClick={() => {
                                  window.scrollTo(0, 0);
                                  setVisible(false);
                                }}
                              >
                                <li>
                                  <input
                                    type="checkbox"
                                    checked={selectedSubcategories.includes(
                                      sub.title
                                    )}
                                  />
                                  {sub.title}
                                </li>
                              </Link>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
              </div>

              <div className="products__filters-sort">
                <h3>Sort By</h3>
                <div className="inputItem">
                  <input
                    type="radio"
                    id="asc"
                    value="asc"
                    name="price"
                    checked={sort === "asc"}
                    onChange={() => handleSortChange("asc")}
                    onClick={() => setVisible(false)}
                  />
                  <label htmlFor="asc">Price (Lowest first)</label>
                </div>
                <div className="inputItem">
                  <input
                    type="radio"
                    id="desc"
                    value="desc"
                    name="price"
                    checked={sort === "desc"}
                    onChange={() => handleSortChange("desc")}
                    onClick={() => setVisible(false)}
                  />
                  <label htmlFor="desc">Price (Highest first)</label>
                </div>
              </div>
            </div>
            <div className="products__side">
              {data?.data?.length > 0 ? (
                <ProductsList products={data} row={4} />
              ) : (
                <p className="no-products">No products found</p>
              )}
              {totalPages > 1 && (
                <div className="products__pagination">
                  <Button
                    primary
                    disabled={pageNumber <= 1}
                    onClick={() => handlePageChange(pageNumber - 1)}
                  >
                    Previous
                  </Button>
                  <div className="products__pagination-num">
                    {getPageNumbers().map((number, index) =>
                      number === "..." ? (
                        <span key={index}>...</span>
                      ) : (
                        <Button
                          key={number}
                          primary={number === pageNumber}
                          outline={number !== pageNumber}
                          onClick={() => handlePageChange(number)}
                        >
                          {number}
                        </Button>
                      )
                    )}
                  </div>
                  <Button
                    primary
                    disabled={pageNumber >= totalPages}
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
