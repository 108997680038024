import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import AccountIcon from "../AccountIcon/AccountIcon";
import NavbarCart from "../NavbarCart/NavbarCart";
import Search from "../SearchIcon/Search";
import "./Navbar.styles.scss";
import Submenu from "../Submenu/Submenu";
import NavbarCategories from "../NavbarCategories/NavbarCategories";

const Navbar = () => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isNavbarSticky, setIsNavbarSticky] = useState(false);
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();

  const changePosition = () => {
    setIsNavbarSticky(window.scrollY >= 40);
  };

  useEffect(() => {
    window.addEventListener("scroll", changePosition);
    return () => {
      window.removeEventListener("scroll", changePosition);
    };
  }, []);

  const hintsTips = [
    "/advantages-of-natural-stone",
    "/natural-stone-vs-ceramic-tile",
    "/installation-principles",
    "/caring-for-stone",
  ].includes(pathname);

  return (
    <div className="navbar">
      <div className="top-nav">
        <div className="container">
          <div className="logo">
            <Link to="/">
              <img
                src="/assets/images/logo.png"
                alt="logo"
                onClick={() => setVisible(false)}
              />
            </Link>
          </div>
          <div className={`links ${visible ? "visible" : ""}`}>
            <NavLink
              to="products"
              className="links__item"
              onClick={() => setVisible(false)}
            >
              Products
            </NavLink>
            <div
              className={`links__item dropdown ${
                hintsTips ? "makeActive" : ""
              }`}
              onMouseEnter={() => setIsSubmenuOpen(true)}
              onMouseLeave={() => setIsSubmenuOpen(false)}
              onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}
            >
              Hints & Tips{" "}
              <span>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.3977 7.66292C7.17802 7.88259 6.82192 7.88259 6.60225 7.66292L0.867387 1.92804C0.64772 1.70837 0.64772 1.35227 0.867387 1.13259L1.13256 0.867393C1.35222 0.647718 1.70838 0.647718 1.92805 0.867393L6.99997 5.93934L12.0719 0.867393C12.2916 0.647718 12.6477 0.647718 12.8674 0.867393L13.1326 1.13259C13.3522 1.35227 13.3522 1.70837 13.1326 1.92804L7.3977 7.66292Z"
                  />
                </svg>
              </span>
              {isSubmenuOpen && <Submenu setVisible={setVisible} />}
            </div>

            <NavLink to="contact-us" className="links__item">
              Contact Us
            </NavLink>
          </div>

          <ul className="commerce">
            <li>
              <Search setVisible={setVisible} />
            </li>
            <Link to="cart">
              <li>
                <NavbarCart setVisible={setVisible} />
              </li>
            </Link>
            <li>
              <Link to="my-account">
                <AccountIcon setVisible={setVisible} />
              </Link>
            </li>
          </ul>
          <div
            class={`navigation__hamburger ${visible ? "active" : ""}`}
            onClick={() => setVisible(!visible)}
          >
            <span class="navigation__hamburger-line1"></span>
            <span class="navigation__hamburger-line2"></span>
            <span class="navigation__hamburger-line3"></span>
          </div>
        </div>
      </div>
      <div className={`bottom-nav ${isNavbarSticky ? "sticky" : ""}`}>
        <div className="container">
          <ul className="categories">
            <NavbarCategories />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
