import React from "react";
import "./Copyright.styles.scss";

const Copyright = () => {
  return (
    <div className="copyright">
      <div className="copyright__logo">
        <img src="assets/images/logo.png" alt="logo" />
      </div>
      <div className="copyright__text">
        <p>&copy; Kaleidorock. All rights reserved.</p>
      </div>
      <div className="copyright__social">
        <ul>
          <li>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36.6667 20.5063C36.6667 11.2453 29.2049 3.73779 20 3.73779C10.7953 3.73779 3.33337 11.2453 3.33337 20.5063C3.33337 28.8758 9.42811 35.8131 17.3959 37.0711V25.3535H13.1641V20.5063H17.3959V16.812C17.3959 12.6094 19.8842 10.288 23.691 10.288C25.5147 10.288 27.4219 10.6155 27.4219 10.6155V14.7421H25.3204C23.25 14.7421 22.6042 16.0348 22.6042 17.361V20.5063H27.2265L26.4877 25.3535H22.6042V37.0711C30.572 35.8131 36.6667 28.8761 36.6667 20.5063Z"
                fill="#F1A008"
              />
            </svg>
          </li>
          <li>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.6667 5.40442H13.3333C8.73097 5.40442 5 9.13539 5 13.7378V27.0711C5 31.6734 8.73097 35.4044 13.3333 35.4044H26.6667C31.269 35.4044 35 31.6734 35 27.0711V13.7378C35 9.13539 31.269 5.40442 26.6667 5.40442ZM32.0833 27.0711C32.0742 30.0588 29.6543 32.4786 26.6667 32.4878H13.3333C10.3456 32.4786 7.92582 30.0588 7.91667 27.0711V13.7378C7.92582 10.75 10.3456 8.33024 13.3333 8.32109H26.6667C29.6543 8.33024 32.0742 10.75 32.0833 13.7378V27.0711ZM27.9167 14.1544C28.8372 14.1544 29.5833 13.4082 29.5833 12.4878C29.5833 11.5673 28.8372 10.8211 27.9167 10.8211C26.9962 10.8211 26.25 11.5673 26.25 12.4878C26.25 13.4082 26.9962 14.1544 27.9167 14.1544ZM20 12.9044C15.8579 12.9044 12.5 16.2623 12.5 20.4044C12.5 24.5466 15.8579 27.9044 20 27.9044C24.1422 27.9044 27.5 24.5466 27.5 20.4044C27.5045 18.4139 26.7157 16.5037 25.3082 15.0962C23.9007 13.6887 21.9905 12.9 20 12.9044ZM15.4167 20.4044C15.4167 22.9358 17.4687 24.9878 20 24.9878C22.5313 24.9878 24.5833 22.9358 24.5833 20.4044C24.5833 17.8731 22.5313 15.8211 20 15.8211C17.4687 15.8211 15.4167 17.8731 15.4167 20.4044Z"
                fill="#F1A008"
              />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Copyright;
