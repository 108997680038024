import React from "react";
import "./Delivery.styles.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const Delivery = () => {
  return (
    <section className="delivery">
      <img src="assets/images/delivery-truck.png" />
      <p>
        Once you have placed your order, we will email you the delivery cost.
        <br />
        Need help? Contact us at 084 899 3742 or sales@kaleidorock.co.za.
      </p>
      <Link to="/checkout">
        <Button primary>Proceed To Checkout</Button>
      </Link>
    </section>
  );
};

export default Delivery;
