import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import categories from "../../categories.json";
import "./NavbarCategories.styles.scss";

const NavbarCategories = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const categoryFromPath = pathname.split("/")[1];
    setActiveCategory(categoryFromPath);
  }, [pathname]);

  const toggleCategory = (index) => {
    setActiveCategory((prev) => (prev === index ? null : index));
  };

  // Filter out the "brands" category
  const filteredCategories = categories.filter(
    (category) => category.title.toLowerCase() !== "brands"
  );

  return (
    <ul className="navbarCategories">
      {filteredCategories.map((category, index) => (
        <li
          key={index}
          className={`navbarCategories__category ${
            activeCategory === index ? "active" : ""
          }`}
        >
          <NavLink
            to={`/${category.title.toLowerCase()}`}
            className="navbarCategories__category-link"
          >
            {category.title}
            {category.subcategories && (
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.3977 7.66292C7.17802 7.88259 6.82192 7.88259 6.60225 7.66292L0.867387 1.92804C0.64772 1.70837 0.64772 1.35227 0.867387 1.13259L1.13256 0.867393C1.35222 0.647718 1.70838 0.647718 1.92805 0.867393L6.99997 5.93934L12.0719 0.867393C12.2916 0.647718 12.6477 0.647718 12.8674 0.867393L13.1326 1.13259C13.3522 1.35227 13.3522 1.70837 13.1326 1.92804L7.3977 7.66292Z"
                />
              </svg>
            )}
          </NavLink>
          {category.subcategories && (
            <ul className="navbarCategories__subcategories">
              {category.subcategories.map((subCategory, subIndex) => (
                <li key={subIndex}>
                  <Link
                    to={`/${category.title.toLowerCase()}/${subCategory.title.toLowerCase()}`}
                  >
                    {subCategory.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default NavbarCategories;
