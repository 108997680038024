import React from "react";
import "./InstallationPrinciples.styles.scss";

const InstallationPrinciples = () => {
  return (
    <section className="installationPrinciple">
      <div className="container">
        <div className="installationPrinciple__heading">
          <h2>Installation Principles</h2>
          <p>
            This system consists of four integral components. The failure of any
            component means the failure of the entire installation.
          </p>
        </div>
        <div className="text-box">
          <img src="assets/images/installation-principle.png" />
          <h3>Substrate</h3>
          <p>
            What is the condition of the substrate that you’re going to set the
            tile on? Is it clean and ready for tile? Is it properly cured? How
            will cracks be prepared? Is it level? Are there any humps, bumps or
            dips? How will you address expansion joints? The number one job
            complaint received by the Tile Council of America (TCA) revolves
            around inadequate preparation of the substrate and no expansion
            joints. Use the TCA Handbook for information regarding the placement
            of movement joints.
          </p>
        </div>
        <div className="text-box">
          <h3>Tile, Trim and Installation Materials</h3>
          <p>
            Overall, tile is much better than it was 20 years ago. There are a
            few sub-standard products, but by and large tile is a bargain over
            the life of the product when compared to other flooring surfaces.
          </p>
          <p>
            <span>Trim:</span> Make sure that the trim shapes perform the
            intended function and that they coordinate in colour, shade,
            thickness and overall dimensions with the tile.{" "}
          </p>
          <p>
            <span>Installation Materials:</span> If you skimp somewhere, don’t
            skimp here. As a matter of fact, many thin-set manufacturers offer
            lifetime warrantees on their premium polymer or latex modified
            setting systems (thin-set and grout). Pennies a foot for a lifetime
            warrantee? Sounds like a bargain insurance plan to me!{" "}
          </p>
        </div>
        <div className="text-box">
          <h3>Installation, caulking and clean-up installation</h3>
          <p>
            Sure tile is a great DIY product, however, you cannot beat the
            finished look of an installation when completed by a true
            professional. A professional tile setter will make sure that the
            substrate is ready to accept the tile, select the proper
            installation materials, layout the installation in a manner that
            will enhance the overall appearance and give you an installation
            that will last at least 40 years.
          </p>
          <p>
            <span>Caulking and clean up:</span> The difference between a good
            installation and a poor installation is in the details. The caulk
            joints, the grout clean up and the overall condition of the
            installation is critical to produce a beautiful job.
          </p>
        </div>
        <div className="text-box">
          <img src="assets/images/installation-principle-1.png" />
          <h3>Substrate</h3>
          <p>
            What is the condition of the substrate that you’re going to set the
            tile on? Is it clean and ready for tile? Is it properly cured? How
            will cracks be prepared? Is it level? Are there any humps, bumps or
            dips? How will you address expansion joints? The number one job
            complaint received by the Tile Council of America (TCA) revolves
            around inadequate preparation of the substrate and no expansion
            joints. Use the TCA Handbook for information regarding the placement
            of movement joints.
          </p>
        </div>
      </div>
    </section>
  );
};

export default InstallationPrinciples;
