import React from "react";
import "./CartItem.styles.scss";
import { useStore } from "../../Context/StoreContext";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

const CartItem = ({ item, orderDetails = false, quantity }) => {
  const {
    handleRemoveCartItem,
    handleCartItemDecrement,
    handleCartItemIncrement,
  } = useStore();

  const cartItemTotal = item.attributes.price * (item.quantity ?? quantity);
  const minimumQuantity = item.attributes.minimum_quantity || 1;

  const handleDecrement = () => {
    if (item.quantity <= minimumQuantity) {
      toast.error(
        `Minimum quantity is ${minimumQuantity} ${item.attributes.price_unit}(s).`
      );
    } else {
      handleCartItemDecrement(item);
    }
  };
  console.log(item);
  return (
    <tr className="cartItem" style={{ width: "100%" }}>
      <td
        className="cartItem__details"
        style={{ width: "100%" }}
        data-label="Product"
      >
        <Link
          to={`/product/${item?.attributes?.name.replace(/\//g, "")}/${
            item.id
          }`}
        >
          <img
            src={
              item.attributes.api_primary_image ??
              process.env.REACT_APP_UPLOAD_URL +
                item.attributes.primary_image.data.attributes.url
            }
            alt=""
          />
          <div className="cartItem__details-container">
            <p className="cartItem__details-title">{item.attributes.name}</p>
          </div>
        </Link>
      </td>
      {!orderDetails && (
        <td style={{ width: "15%" }} data-label="Price">
          <div className="cartItem__price">
            <p>
              {`R ${item.attributes.price.toFixed(2)}`}{" "}
              <span>{item.attributes.price_unit}</span>
            </p>
          </div>
        </td>
      )}
      <td
        style={!orderDetails ? { width: "20%" } : { width: "15%" }}
        data-label="Quantity"
      >
        <div className="cartItem__quantity">
          {!orderDetails && <span onClick={handleDecrement}>-</span>}
          {!orderDetails ? (
            <input type="text" value={item.quantity} readOnly />
          ) : (
            `Qty: ${item.quantity ?? quantity}`
          )}
          {!orderDetails && (
            <span onClick={() => handleCartItemIncrement(item)}>+</span>
          )}
        </div>
      </td>
      <td style={orderDetails ? { width: "13%" } : {}} data-label="Total">
        <div className="cartItem__total">
          <p>R {(Math.round(cartItemTotal * 100) / 100).toFixed(2)}</p>
        </div>
      </td>
      {!orderDetails && (
        <td data-label="">
          <div className="cartItem__delete">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleRemoveCartItem(item)}
            >
              <g clipPath="url(#clip0_97_2002)">
                <path
                  d="M21 1.54327H14C14 1.27806 13.8946 1.0237 13.7071 0.836167C13.5196 0.648631 13.2652 0.543274 13 0.543274H11C10.7348 0.543274 10.4804 0.648631 10.2929 0.836167C10.1054 1.0237 10 1.27806 10 1.54327H3C2.73478 1.54327 2.48043 1.64863 2.29289 1.83617C2.10536 2.0237 2 2.27806 2 2.54327C2 2.80849 2.10536 3.06284 2.29289 3.25038C2.48043 3.43792 2.73478 3.54327 3 3.54327H21C21.2652 3.54327 21.5196 3.43792 21.7071 3.25038C21.8946 3.06284 22 2.80849 22 2.54327C22 2.27806 21.8946 2.0237 21.7071 1.83617C21.5196 1.64863 21.2652 1.54327 21 1.54327Z"
                  fill="#F12D2D"
                />
                <path
                  d="M19.5 4.54327C19.2348 4.54327 18.9804 4.64863 18.7929 4.83617C18.6054 5.0237 18.5 5.27806 18.5 5.54327V21.5433H5.5V5.54327C5.5 5.27806 5.39464 5.0237 5.20711 4.83617C5.01957 4.64863 4.76522 4.54327 4.5 4.54327C4.23478 4.54327 3.98043 4.64863 3.79289 4.83617C3.60536 5.0237 3.5 5.27806 3.5 5.54327V22.5433C3.5 22.8085 3.60536 23.0628 3.79289 23.2504C3.98043 23.4379 4.23478 23.5433 4.5 23.5433H19.5C19.7652 23.5433 20.0196 23.4379 20.2071 23.2504C20.3946 23.0628 20.5 22.8085 20.5 22.5433V5.54327C20.5 5.27806 20.3946 5.0237 20.2071 4.83617C20.0196 4.64863 19.7652 4.54327 19.5 4.54327Z"
                  fill="#F12D2D"
                />
                <path
                  d="M10.5 18.5433V9.54327C10.5 9.27806 10.3946 9.0237 10.2071 8.83617C10.0196 8.64863 9.76522 8.54327 9.5 8.54327C9.23478 8.54327 8.98043 8.64863 8.79289 8.83617C8.60536 9.0237 8.5 9.27806 8.5 9.54327V18.5433C8.5 18.8085 8.60536 19.0628 8.79289 19.2504C8.98043 19.4379 9.23478 19.5433 9.5 19.5433C9.76522 19.5433 10.0196 19.4379 10.2071 19.2504C10.3946 19.0628 10.5 18.8085 10.5 18.5433Z"
                  fill="#F12D2D"
                />
                <path
                  d="M15.5 18.5433V9.54327C15.5 9.27806 15.3946 9.0237 15.2071 8.83617C15.0196 8.64863 14.7652 8.54327 14.5 8.54327C14.2348 8.54327 13.9804 8.64863 13.7929 8.83617C13.6054 9.0237 13.5 9.27806 13.5 9.54327V18.5433C13.5 18.8085 13.6054 19.0628 13.7929 19.2504C13.9804 19.4379 14.2348 19.5433 14.5 19.5433C14.7652 19.5433 15.0196 19.4379 15.2071 19.2504C15.3946 19.0628 15.5 18.8085 15.5 18.5433Z"
                  fill="#F12D2D"
                />
              </g>
              <defs>
                <clipPath id="clip0_97_2002">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.0432739)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </td>
      )}
    </tr>
  );
};

export default CartItem;
