import { Link } from "react-router-dom";
import "./ProductItem.styles.scss";
import { useEffect } from "react";

const ProductItem = ({ product, row }) => {
  return (
    <div className={`productItem ${row ? "third" : ""}`}>
      <Link
        to={`/product/${product?.attributes?.name.replace(/\//g, "")}/${
          product.id
        }`}
      >
        <div className="productItem__image">
          <img
            src={
              product?.attributes?.api_primary_image
                ? product?.attributes?.api_primary_image
                : process.env.REACT_APP_UPLOAD_URL +
                  product?.attributes?.primary_image?.data?.attributes?.url
            }
            alt={product?.attributes?.name}
          />
          {product?.attributes?.is_on_sale === "yes" && <span>Sale</span>}
        </div>
        <div className="productItem__info">
          <p className="productItem__info-brand">
            {product?.attributes?.brand}
          </p>
          <p className="productItem__info-title">{product?.attributes?.name}</p>
          <p className="productItem__info-category">
            {product?.attributes?.sub_category?.data?.attributes?.title}
          </p>
          <p className="productItem__info-promo">
            {product?.attributes?.sale_price &&
              `R${product?.attributes?.sale_price}`}
          </p>
          <p className="productItem__info-price">
            R {product?.attributes?.price.toFixed(2)}{" "}
            <span>{product?.attributes?.price_unit}</span>
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ProductItem;
