import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { useStore } from "../../Context/StoreContext";
import { makeRequest } from "../../hooks/makeRequest";
import FormInput from "../FormInput/FormInput";
import Loader from "../Loader/Loader";
import OrderSummary from "../OrderSummary/OrderSummary";
import TelephoneInput from "../TelephoneInput/TelephoneInput";
import "./Checkout.styles.scss";

const CheckoutForm = () => {
  const { cart, handleUserLogin, user, cartTotal, handleClearCart } =
    useStore();

  const [formData, setFormData] = useState({
    first_name: user ? user.first_name : "",
    last_name: user ? user.last_name : "",
    email: user ? user.email : "",
    password: "",
    confirm_password: "",
    phone_number: user ? user.phone_number : "",
    company_name: "",
    street_name: "",
    apartment_suite_unit: "",
    town_city: "",
    province: "",
    post_code: "",
    country: "South Africa",
    notes: "",
  });

  const [createAccount, setCreateAccount] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch countries data for the dropdown
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countryOptions = response.data.map((country) => ({
        value: country.name.common,
        label: country.name.common,
      }));
      setCountries(countryOptions);
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({
      ...formData,
      country: selectedOption.value,
    });
  };

  const handleCheckboxChange = () => {
    setCreateAccount(!createAccount);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (cart <= 0) {
      toast.error("Your cart is empty");
      return;
    }
    // Validate form fields
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    let userId;
    try {
      if (createAccount && !user) {
        const userResponse = await makeRequest.post("/auth/local/register", {
          username: formData.email,
          email: formData.email,
          password: formData.password,
          confirm_password: formData.confirm_password,
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone_number: formData.phone_number,
          company_name: formData.company_name,
          street_name: formData.street_name,
          apartment_suite_unit: formData.apartment_suite_unit,
          town_city: formData.town_city,
          province: formData.province,
          post_code: formData.post_code,
          country: formData.country,
        });
        userId = userResponse.id;
        toast.success("User registered successfully!");
        handleUserLogin(userResponse.data.user);
      }

      const summary = cart.map((item) => {
        return {
          name: item.attributes.name,
          quantity: item.quantity,
          primary_image: item?.attributes?.primary_image?.data?.id,
          price: item.attributes.price,
          price_unit: item.attributes.price_unit,
          api_primary_image: item?.attributes?.api_primary_image,
          product: item.id,
        };
      });

      const orderResponse = await makeRequest.post("orders", {
        data: {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          phone_number: formData.phone_number,
          company_name: formData.company_name,
          street_name: formData.street_name,
          apartment_suite_unit: formData.apartment_suite_unit,
          town_city: formData.town_city,
          province: formData.province,
          post_code: formData.post_code,
          country: formData.country,
          notes: formData.notes,
          order_date: new Date().toISOString(),
          user: user ? user.id : userId,
          order_amount: cartTotal,
          orderedItems: summary,
        },
      });

      const orderId = orderResponse.data.data.id;

      const form = $(e.target);

      // Manually create the data object
      const manualData = {
        first_name: orderResponse.data.data.attributes.first_name,
        last_name: orderResponse.data.data.attributes.last_name,
        email: orderResponse.data.data.attributes.email,
        amount: orderResponse.data.data.attributes.order_amount,
        orderNumber: orderResponse.data.data.id,
      };

      $.ajax({
        type: "POST",
        url: form.attr("action"),
        data: manualData,
        success(data) {
          window.payfast_do_onsite_payment(
            { uuid: `${data}`, return_url: "https://kaleidorock.com/checkout" },
            function (result) {
              if (result === true) {
                const changePaymentStatus = async () => {
                  const orderPaymentsResponse = await makeRequest.put(
                    `/orders/${orderId}`, // Assuming `orderId` is already defined
                    {
                      data: {
                        first_name: formData.first_name,
                        last_name: formData.last_name,
                        email: formData.email,
                        phone_number: formData.phone_number,
                        company_name: formData.company_name,
                        street_name: formData.street_name,
                        apartment_suite_unit: formData.apartment_suite_unit,
                        town_city: formData.town_city,
                        province: formData.province,
                        post_code: formData.post_code,
                        country: formData.country,
                        notes: formData.notes,
                        order_date: new Date().toISOString(),
                        user: user ? user.id : userId,
                        order_amount: cartTotal,
                        payment_status: "paid",
                      },
                    }
                  );
                  handleClearCart();
                  window.location.href = `/checkout/invoice/${orderId}`;
                };
                changePaymentStatus();
              } else {
                // Handle payment failure
                const deleteOrder = async () => {
                  const orderPaymentsResponse = await makeRequest.delete(
                    `/orders/${orderId}`
                  );

                  setLoading(false);
                };
                deleteOrder();
              }
            }
          );
        },
      });
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.message);
    }
  };

  const validateForm = () => {
    let isValid = true;
    if (!formData.first_name.trim() && !user) {
      toast.error("First Name is required");
      isValid = false;
    }
    if (!formData.last_name.trim() && !user) {
      toast.error("Last Name is required");
      isValid = false;
    }
    if (!formData.email.trim() && !user) {
      toast.error("Email is required");
      isValid = false;
    }
    if (!formData.phone_number.trim() && !user) {
      toast.error("Phone Number is required");
      isValid = false;
    }
    if (!formData.street_name.trim()) {
      toast.error("Street Name is required");
      isValid = false;
    }
    if (!formData.town_city.trim()) {
      toast.error("Town/City is required");
      isValid = false;
    }
    if (!formData.province.trim()) {
      toast.error("Province is required");
      isValid = false;
    }
    if (!formData.post_code.trim()) {
      toast.error("Post Code is required");
      isValid = false;
    }
    if (createAccount && formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match");
      isValid = false;
    }
    return isValid;
  };

  if (loading) return <Loader full />;

  return (
    <form
      action="https://kaleidorock.com/server.php"
      method="post"
      onSubmit={handleSubmit}
      className="checkout"
    >
      <div className="checkout__form">
        <FormInput
          type="text"
          name="first_name"
          value={user ? user.first_name : formData.first_name}
          readOnly={user}
          onChange={handleChange}
          half
          label="First Name"
        />
        <FormInput
          label="Last Name"
          type="text"
          name="last_name"
          value={user ? user.last_name : formData.last_name}
          onChange={handleChange}
          half
          readOnly={user}
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={user ? user.email : formData.email}
          onChange={handleChange}
          half
          readOnly={user}
        />

        <TelephoneInput
          label="Phone Number"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
        />
        <FormInput
          label="Company Name"
          type="text"
          name="company_name"
          value={formData.company_name}
          onChange={handleChange}
        />
        <FormInput
          label="Street Name"
          type="text"
          name="street_name"
          value={formData.street_name}
          onChange={handleChange}
        />
        <FormInput
          label="Apartment,Suite,Unit"
          type="text"
          name="apartment_suite_unit"
          value={formData.apartment_suite_unit}
          onChange={handleChange}
        />
        <FormInput
          label="Town/City"
          type="text"
          name="town_city"
          value={formData.town_city}
          onChange={handleChange}
        />
        <FormInput
          type="text"
          name="province"
          label="Province"
          value={formData.province}
          onChange={handleChange}
        />
        <FormInput
          type="text"
          name="post_code"
          value={formData.post_code}
          onChange={handleChange}
          half
          label="Post Code"
        />
        <div className="country_select">
          <span>Country</span>
          <Select
            options={countries}
            value={{ value: formData.country, label: formData.country }}
            onChange={handleCountryChange}
            defaultValue={{ value: "South Africa", label: "South Africa" }}
          />
        </div>
        <div className="notes">
          <label>Notes</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            placeholder="Notes"
            rows={10}
          ></textarea>
        </div>
        {!user && (
          <div className="account">
            <h4>Account Details</h4>
            <label className="account_label">
              <input
                type="checkbox"
                checked={createAccount}
                onChange={handleCheckboxChange}
                label="Create an account"
              />
              Create an account
            </label>
            {createAccount && (
              <>
                <FormInput
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  label="Password"
                />

                <FormInput
                  label="Confirm Password"
                  type="password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
        )}
      </div>
      <input type="hidden" name="amount" value={cartTotal} />
      <input type="hidden" name="orderNumber" value="#125322" />

      <div className="checkout__summary">
        <OrderSummary buttonType="submit" />
      </div>
    </form>
  );
};

export default CheckoutForm;
