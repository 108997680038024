import "./Button.styles.scss";

const Button = ({
  children,
  primary,
  outline,
  black,
  minimal,
  google,
  onClick,
  disabled = false,
  small,
  buttonType,
}) => {
  return (
    <button
      type={buttonType && buttonType}
      disabled={disabled}
      onClick={onClick}
      className={`${primary ? "primary" : ""} ${outline ? "outline" : ""}${
        black ? "black" : ""
      } ${minimal ? "minimal" : ""} ${google ? "google" : ""} ${
        small ? "small" : ""
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
