import React from "react";
import "./AdvantageOfNaturalStonePage.styles.scss";

const AdvantageOfNaturalStonePage = () => {
  return (
    <>
      <section className="advantages first">
        <div className="container">
          <img src="assets/images/natural-stone.png" />
          <div className="advantages__content">
            <h3>Advantages of Natural Stone</h3>
            <div className="text-box">
              <h3>Natural Stone Tiles are Distinct and Unique</h3>
              <p>
                You should always view several pieces of the tile that is to be
                installed before the installation begins. Since it has been
                created by nature, not only are no two pieces exactly alike,
                those two pieces may not even be very similar.
              </p>
            </div>
            <div className="text-box">
              <h3>The Most Common Natural Stone Tiles Include:</h3>
              <p>
                Slate, flagstone (sandstone), marble, granite, travertine and
                limestone. Each stone has unique characteristics and maintenance
                requirements. Generally, you should seal natural stone tile
                before grouting, unless you are planning on using the grout
                colour in the stone as a design element.
              </p>
              <p>
                Most natural stones are not resistant against common household
                acids (like lemon juice) or oil stains. Therefore, you should
                use a penetrating sealer for all natural stones after
                installation.
              </p>
            </div>
          </div>
        </div>
        <div className="container"></div>
      </section>
      <section className="advantages">
        <div className="container ">
          <div className="advantages__content ">
            <div className="text-box">
              <h3>The Most Common Natural Stone Tiles Include:</h3>
              <ul>
                <li>Timeless, unique appearance. </li>
                <li>Design capabilities of through-body colour material. </li>
                <li>Perceived value.</li>
                <li>Durable countertops and floors (granite).</li>
                <li>May be re-polished if scratched</li>
              </ul>
            </div>
          </div>
          <img src="assets/images/natural-stone-1.png" />
        </div>
        <div className="container"></div>
      </section>
    </>
  );
};

export default AdvantageOfNaturalStonePage;
