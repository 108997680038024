import React from "react";
import "./CartPage.styles.scss";
import Button from "../../components/Button/Button";
import CartItem from "../../components/CartItem/CartItem";
import TotalButton from "../../components/TotalButton/TotalButton";
import { useStore } from "../../Context/StoreContext";
import EmptyCart from "../../components/EmptyCart/EmptyCart";

const CartPage = () => {
  const { cart, handleClearCart } = useStore();

  return (
    <>
      <section className="cart">
        <div className="container">
          <h1>Cart</h1>
          {cart.length === 0 && <EmptyCart />}
          {cart?.length >= 1 && (
            <>
              <div className="cart__emptyCart">
                <Button outline onClick={handleClearCart}>
                  Empty Cart
                </Button>
              </div>
              <table className="cart__summary">
                <thead>
                  <tr>
                    <th style={{ width: "40%" }}>Product</th>
                    <th style={{ width: "20%" }}>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cart &&
                    cart.map((item) => {
                      return <CartItem item={item} key={item.id} />;
                    })}
                </tbody>
              </table>
              <TotalButton back cart={cart}>
                PROCEED TO CHECKOUT
              </TotalButton>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default CartPage;
