import React from "react";
import "./Testimonials.styles.scss";
import Carousel from "../Carousel/Carousel";
import useFetch from "../../hooks/useFetch";

const Testimonials = () => {
  const { data, loading } = useFetch("/reviews?populate=*");

  return (
    <div className="testimonials">
      <Carousel>
        {data.data &&
          data.data.map((item) => {
            return (
              <div className="testimonials__item">
                <img
                  src={`${
                    process.env.REACT_APP_UPLOAD_URL +
                    item.attributes.image.data.attributes.url
                  }`}
                />
                <p>"{item.attributes.review}"</p>
                <h3>{item.attributes.name}</h3>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};

export default Testimonials;
