import React from "react";
import "./Footer.styles.scss";
import Newsletter from "../Newsletter/Newsletter";
import Spacer from "../Spacer/Spacer";
import FooterLinks from "../FooterLinks/FooterLinks";
import Copyright from "../Copyright/Copyright";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <Newsletter />
        <Spacer />
        <FooterLinks />
        <Spacer />
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
