import React from "react";
import "./Sidebar.styles.scss";
import { NavLink } from "react-router-dom";
import { useStore } from "../../Context/StoreContext";

const Sidebar = () => {
  const { handleUserLogout } = useStore();
  return (
    <div className="sidebar">
      <h1>My Account</h1>
      <div className="sidebar__links">
        <NavLink className="sidebar__links-item" to="account-details">
          Account Details
        </NavLink>
        <NavLink className="sidebar__links-item" to="orders">
          Order Details
        </NavLink>
        <p className="sidebar__links-item" onClick={handleUserLogout}>
          Logout
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
