import React from "react";
import "./Orders.styles.scss";
import Button from "../Button/Button";
import { useStore } from "../../Context/StoreContext";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
const Orders = () => {
  const { user } = useStore();
  const { data, loading, error } = useFetch(
    `orders?populate=orderedItems.primary_image&filters[email][$eq]=${user.email}`
  );

  return (
    <div className="orders">
      <h3>Orders</h3>
      {data?.data?.map((item) => {
        return (
          <div className="orders__item" key={item.id}>
            <div className="orders__item-heading">
              <div className="orders__item-detail">
                <p>
                  Order Status : <span>{item?.attributes?.order_status}</span>
                </p>
                <p>
                  Order Date :{" "}
                  {new Date(item?.attributes?.order_date).toLocaleDateString(
                    "en-GB"
                  )}
                </p>
                <p>Order No : #{item?.id}</p>
              </div>
              <Link to={`${item.id}`}>
                <Button primary>Order Details</Button>
              </Link>
            </div>
            <div className="orders__item-images">
              {item?.attributes?.orderedItems?.slice(0, 3).map((i) => {
                return (
                  <img
                    key={i.id}
                    src={
                      i.api_primary_image
                        ? i.api_primary_image
                        : process.env.REACT_APP_UPLOAD_URL +
                          i.primary_image?.data?.attributes?.url
                    }
                  />
                );
              })}
              <h3>
                {item?.attributes?.orderedItems.length > 3
                  ? `+ ${item?.attributes?.orderedItems.length - 3} more`
                  : ""}
              </h3>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Orders;
