import React from "react";
import "./MiniCart.styles.scss";
import MiniCartItem from "../MiniCartItem/MiniCartItem";
import TotalButton from "../TotalButton/TotalButton";
import { useStore } from "../../Context/StoreContext";
import EmptyCart from "../EmptyCart/EmptyCart";

const MiniCart = ({ title = false, close = false }) => {
  const { cart, handleClearCart } = useStore();
  return (
    <div className="miniCart">
      {title && (
        <div className={`miniCart-heading ${close ? "miniCart-light" : ""}`}>
          <h3>{title}</h3>
          {close && <span>&#x2715;</span>}
        </div>
      )}
      <div className="miniCart-content">
        {cart &&
          cart.map((item) => {
            return <MiniCartItem bin item={item} title="cart" key={item.id} />;
          })}
        {cart.length < 1 && (
          <div className="miniCart-empty">
            <p>Your cart is empty</p>
          </div>
        )}
      </div>
      {cart.length >= 1 && (
        <div className="miniCart-footer">
          <TotalButton cart={cart}>Proceed To Checkout</TotalButton>
        </div>
      )}
    </div>
  );
};

export default MiniCart;
