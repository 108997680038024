import React from "react";
import "./AccountIcon.styles.scss";

const AccountIcon = () => {
  return (
    <div className="accountIcon">
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.3052 16.1312C22.431 14.5045 23.8077 11.9492 23.8077 9.06604C23.8077 4.15046 19.8223 0.165039 14.9067 0.165039C9.99115 0.165039 6.00573 4.15046 6.00573 9.06604C6.00573 11.9492 7.38241 14.5045 9.50827 16.1312C4.40355 18.3609 0.813477 23.6526 0.813477 29.835H29C29 23.6526 25.4099 18.3609 20.3052 16.1312ZM8.97273 9.06604C8.97273 5.79418 11.6349 3.13204 14.9067 3.13204C18.1786 3.13204 20.8407 5.79418 20.8407 9.06604C20.8407 12.3379 18.1786 15 14.9067 15C11.6349 15 8.97273 12.3379 8.97273 9.06604ZM14.9067 17.967C20.0819 17.967 24.4427 21.7552 25.6806 26.868h3.13281C5.37079 21.7552 9.73154 17.967 14.9067 17.967Z" />
      </svg>
    </div>
  );
};

export default AccountIcon;
