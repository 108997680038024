import React, { useState } from "react";
import FormInput from "../FormInput/FormInput";
import "./Newsletter.styles.scss";
import Button from "../Button/Button";
import toast from "react-hot-toast";
import { makeRequest } from "../../hooks/makeRequest";
import Loader from "../Loader/Loader";

const Newsletter = ({ title = true }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const addNewsletterSubscriber = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await makeRequest.post(`/newsletter-subscribers`, {
        data: { email },
      });
      toast.success(
        "Thank you for subscribing! You've successfully joined our newsletter."
      );
      setEmail("");
    } catch (error) {
      const errorMessage =
        error.response.data.error.message === "This attribute must be unique"
          ? "It looks like you're already subscribed to our newsletter!"
          : error.response.data.error.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => setEmail(e.target.value);

  return (
    <div className="newsletter">
      {title && <h3>Join Our Newsletter</h3>}
      <form onSubmit={addNewsletterSubscriber}>
        <FormInput
          large
          placeholder="Your Email"
          value={email}
          onChange={handleChange}
        />
        <Button primary type="submit">
          Subscribe
          {loading && <Loader small />}
        </Button>
      </form>
    </div>
  );
};

export default Newsletter;
