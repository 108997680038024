import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../Context/StoreContext";
import Button from "../Button/Button";
import MeasurementModal from "../MeasurementModal/MeasurementModal"; // Import the modal component
import "./BoxCalculator.styles.scss";

function BoxCalculator({ size = "medium", product }) {
  const coverageUnit = product.data.attributes.coverage_square_meters;
  const minimum_quantity = product?.data?.attributes?.minimum_quantity;
  const [quantity, setQuantity] = useState(1);
  const [squareMeters, setSquareMeters] = useState(coverageUnit);
  const [added, setAdded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const { addToCart } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    setQuantity(1);
    setSquareMeters(coverageUnit);
  }, [product, coverageUnit]);

  const handleQuantityIncrement = () => {
    setQuantity(quantity + 1);
    setSquareMeters((quantity + 1) * coverageUnit);
  };

  const handleQuantityDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setSquareMeters((quantity - 1) * coverageUnit);
    }
  };

  const handleSquareMetersIncrement = () => {
    setSquareMeters(squareMeters + coverageUnit);
    setQuantity(
      Math.max(1, Math.round((squareMeters + coverageUnit) / coverageUnit))
    );
  };

  const handleSquareMetersDecrement = () => {
    if (squareMeters > 0) {
      setSquareMeters(Math.max(0, Math.ceil(squareMeters - coverageUnit)));
      setQuantity(
        Math.max(1, Math.round((squareMeters - coverageUnit) / coverageUnit))
      );
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    setQuantity(newQuantity);
    setSquareMeters(newQuantity * coverageUnit);
  };

  const handleSquareMetersChange = (event) => {
    const newSquareMeters = parseFloat(event.target.value);
    setSquareMeters(newSquareMeters);
    setQuantity(Math.max(1, Math.round(newSquareMeters / coverageUnit)));
  };

  const handleAddToCart = () => {
    setAdded(true);
    addToCart(product.data, quantity);
  };

  const handleCheckout = () => {
    navigate("/cart");
  };

  return (
    <div className={`boxCalculator ${size}`}>
      <div className="inputs">
        {minimum_quantity && (
          <p className="warning">
            Minimum quantity is {product?.data?.attributes?.minimum_quantity}{" "}
            {product?.data?.attributes?.price_unit}
          </p>
        )}
        <div className="input-group">
          <label className="input-label">Boxes</label>
          <div className="input-container">
            <button
              className="input-container"
              onClick={handleQuantityDecrement}
            >
              -
            </button>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
            />
            <button onClick={handleQuantityIncrement}>+</button>
          </div>
        </div>
        <div className="input-group">
          <label className="input-label">Square Meters</label>
          <div className="input-container">
            <button onClick={handleSquareMetersDecrement}>-</button>
            <input
              type="number"
              value={squareMeters}
              onChange={handleSquareMetersChange}
            />
            <button onClick={handleSquareMetersIncrement}>+</button>
          </div>
        </div>
        <Button outline onClick={() => setIsModalOpen(true)}>
          Help Me Measure
        </Button>
        <Button
          disabled={quantity < minimum_quantity}
          primary
          onClick={added ? handleCheckout : handleAddToCart}
        >
          {added ? "Checkout" : "Add to Cart"}
        </Button>
        <Button black onClick={() => navigate(-1)}>
          Continue Shopping
        </Button>
      </div>
      <MeasurementModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        product={product}
      />
    </div>
  );
}

export default BoxCalculator;
