import { useEffect, useState } from "react";
import { makeRequest } from "./makeRequest";
import toast from "react-hot-toast";

const useFetch = (url, dependencies = []) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await makeRequest.get(url);
        setData(res.data); // Ensure to use res.data to get the data from Axios response
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [url, ...dependencies]);

  return { data, loading, error };
};

export default useFetch;
