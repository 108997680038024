import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Carousel.styles.scss";

const Carousel = ({ children }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    cssEase: "linear",
  };
  return (
    <div className="slider">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default Carousel;
