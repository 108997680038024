import React from "react";
import "./Invoice.styles.scss";
import useFetch from "../../hooks/useFetch";
import { useParams } from "react-router-dom";

const Invoice = () => {
  const { id } = useParams();
  const { data, loading } = useFetch(
    `/orders/${id}?populate[orderedItems][populate]=primary_image,product.primary_image&`,
    [id]
  );

  return (
    <div className="invoice">
      <body>
        <div class="invoice-container">
          <div className="header">
            <div>
              <img src="/assets/images/logo.png" />
              <h1>Invoice</h1>
              <p>Order No #{data?.data?.id}</p>
            </div>
            <div>
              <h1>{data?.data?.attributes?.payment_status}</h1>
            </div>
          </div>
          <div class="invoice-header">
            <div class="invoice-to">
              <p>
                Date:{" "}
                {new Date(
                  data?.data?.attributes?.order_date
                ).toLocaleDateString("en-GB")}
              </p>

              <p>{data?.data?.attributes?.company_name}</p>
              <p>
                {data?.data?.attributes?.first_name +
                  " " +
                  data?.data?.attributes?.last_name}
              </p>
              <p className="invoice-email">{data?.data?.attributes?.email}</p>
              <p>{`+${data?.data?.attributes?.phone_number}`}</p>
            </div>

            <div class="company-details">
              <p>{data?.data?.attributes?.street_name}</p>
              <p>{data?.data?.attributes?.apartment_suite_unit}</p>
              <p>{data?.data?.attributes?.town_city}</p>
              <p>{data?.data?.attributes?.province}</p>
              <p>{data?.data?.attributes?.country}</p>
              <p>{data?.data?.attributes?.post_code}</p>
            </div>
          </div>

          <table class="invoice-table">
            <thead>
              <tr>
                <th>Items Description</th>
                <th>Unit Price</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.attributes?.orderedItems.map((item) => {
                return (
                  <tr>
                    <td>{item?.name}</td>
                    <td>R {item?.price.toFixed(2)}</td>
                    <td>{item?.quantity}</td>
                    <td>R {item?.price.toFixed(2) * item?.quantity}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div class="totals">
            <p class="total-amount">
              TOTAL DUE: {`R ${data?.data?.attributes?.order_amount}`}
            </p>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Invoice;
