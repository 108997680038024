import React, { useEffect, useState } from "react";
import "./ProductDetails.styles.scss";
import Button from "../Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../Context/StoreContext";
import Loader from "../Loader/Loader";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ReviewsItem from "../ReviewsItem/ReviewsItem";
import useFetch from "../../hooks/useFetch";
import ProductsList from "../ProductsList/ProductsList";
import BoxCalculator from "../BoxCalculator/BoxCalculator";

const ProductDetails = () => {
  const { data } = useFetch("/products?populate=*");
  const [currentTab, setCurrentTab] = useState("specifications");
  const [productValue, setProductValue] = useState(1);
  const [mainImage, setMainImage] = useState("");
  const [added, setAdded] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getProduct, currentProduct, isLoading, addToCart } = useStore();

  useEffect(() => {
    getProduct(id);
    setAdded(false);
  }, [id]);

  useEffect(() => {
    if (currentProduct) {
      setMainImage(
        currentProduct?.data?.attributes?.api_primary_image
          ? currentProduct?.data?.attributes?.api_primary_image
          : process.env.REACT_APP_UPLOAD_URL +
              currentProduct?.data?.attributes?.primary_image.data.attributes
                .url
      );
    }
  }, [currentProduct]);
  const { data: relatedDate, loading: relatedLoading } = useFetch(
    `/products?populate=*&filters[category][title]=${currentProduct?.data?.attributes?.category?.data?.attributes?.title}`
  );

  function handleQuantityMinus() {
    if (productValue > 1) {
      setProductValue((prevValue) => prevValue - 1);
    }
  }

  function handleQuantityAdd() {
    setProductValue((prevValue) => prevValue + 1);
  }

  function shuffleArray(array) {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  if (isLoading || !currentProduct) {
    return <Loader />;
  }

  // Filter related products excluding the current product
  // Filter related products excluding the current product
  let filterData = relatedDate?.data?.filter((product) => {
    return (
      product.id !== currentProduct?.data?.id &&
      product?.attributes?.category?.data?.attributes?.title ===
        currentProduct?.data?.attributes?.category?.data?.attributes?.title
    );
  });

  // Randomize the filtered data

  filterData = shuffleArray(filterData);
  console.log(currentProduct?.data?.attributes?.minimum_quantity);
  return (
    <>
      <ScrollToTop />
      <section className="productDetails">
        <div className="container">
          <div className="productDetails__left">
            {(currentProduct?.data?.attributes?.api_secondary_image ||
              currentProduct?.data?.attributes?.secondary_image?.data
                ?.attributes?.url) && (
              <div className="productDetails__left-sideImages">
                {/* Primary Image */}
                {currentProduct?.data?.attributes?.api_primary_image && (
                  <img
                    src={currentProduct?.data?.attributes?.api_primary_image}
                    alt="Primary product"
                    onClick={() =>
                      setMainImage(
                        currentProduct?.data?.attributes?.api_primary_image
                      )
                    }
                  />
                )}

                {/* Secondary Image */}
                {currentProduct?.data?.attributes?.api_secondary_image && (
                  <img
                    src={currentProduct?.data?.attributes?.api_secondary_image}
                    alt="Secondary product"
                    onClick={() =>
                      setMainImage(
                        currentProduct?.data?.attributes?.api_secondary_image
                      )
                    }
                  />
                )}

                {/* Fallback Images */}
                {!currentProduct?.data?.attributes?.api_secondary_image &&
                  currentProduct?.data?.attributes?.secondary_image?.data
                    ?.attributes?.url && (
                    <img
                      src={
                        process.env.REACT_APP_UPLOAD_URL +
                        currentProduct?.data?.attributes?.primary_image.data
                          .attributes.url
                      }
                      alt="Fallback primary product"
                      onClick={() =>
                        setMainImage(
                          process.env.REACT_APP_UPLOAD_URL +
                            currentProduct?.data?.attributes?.primary_image.data
                              .attributes.url
                        )
                      }
                    />
                  )}

                {!currentProduct?.data?.attributes?.api_secondary_image &&
                  currentProduct?.data?.attributes?.secondary_image?.data
                    ?.attributes?.url && (
                    <img
                      src={
                        process.env.REACT_APP_UPLOAD_URL +
                        currentProduct?.data?.attributes?.secondary_image.data
                          .attributes.url
                      }
                      alt="Fallback secondary product"
                      onClick={() =>
                        setMainImage(
                          process.env.REACT_APP_UPLOAD_URL +
                            currentProduct?.data?.attributes?.secondary_image
                              .data.attributes.url
                        )
                      }
                    />
                  )}
              </div>
            )}

            <div className="productDetails__left-mainImage">
              <img src={mainImage} alt="Main" />
            </div>
          </div>
          <div className="productDetails__right">
            <h3 className="productDetails__right-heading">
              {currentProduct?.data?.attributes?.name}
            </h3>
            <p className="productDetails__right-cat">
              {currentProduct?.data?.attributes?.category.data.attributes.title}
            </p>

            <h3 className="productDetails__right-price">
              R {currentProduct?.data?.attributes?.price.toFixed(2)}{" "}
              <span>{currentProduct?.data?.attributes?.price_unit}</span>
            </h3>

            {/* <BoxCalculator product={currentProduct} showMeasure={true} /> */}
            <p className="productDetails__right-desc">
              {currentProduct?.data?.attributes?.description}
            </p>
            {!currentProduct?.data?.attributes?.coverage_square_meters ? (
              <>
                <div className="productDetails__quantity">
                  <span
                    className="productDetails__quantity-dec"
                    onClick={handleQuantityMinus}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={productValue}
                    onChange={(e) => setProductValue(Number(e.target.value))}
                    readOnly
                  />
                  <span
                    className="productDetails__quantity-inc"
                    onClick={handleQuantityAdd}
                  >
                    +
                  </span>
                </div>
                <Button
                  primary
                  disabled={
                    productValue <
                    currentProduct.data.attributes.minimum_quantity
                  }
                  onClick={
                    added
                      ? () => (window.location = "/checkout")
                      : () => {
                          addToCart(currentProduct.data, productValue);
                          setAdded(true);
                        }
                  }
                >
                  {added ? "Checkout" : "Add to Cart"}
                </Button>
                {currentProduct?.data?.attributes?.minimum_quantity && (
                  <p className="warning">
                    Minimum quantity is{" "}
                    {currentProduct?.data?.attributes?.minimum_quantity}{" "}
                    {currentProduct?.data?.attributes?.price_unit}
                  </p>
                )}
                <Button
                  black
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Continue Shopping
                </Button>
              </>
            ) : (
              <BoxCalculator product={currentProduct} />
            )}
          </div>
        </div>
      </section>
      <section className="productSpecs">
        <div className="container">
          <div className="productSpecs__tabs">
            <p
              onClick={() => setCurrentTab("specifications")}
              className={currentTab === "specifications" ? "active" : ""}
            >
              Specification
            </p>
            <p
              onClick={() => setCurrentTab("reviews")}
              className={currentTab === "reviews" ? "active" : ""}
            >
              Reviews
            </p>
          </div>
          {currentTab === "specifications" && (
            <div className="productSpecs__specs">
              {currentProduct?.data?.attributes?.product_code && (
                <div className="productSpecs__specs-item">
                  <p>Product Code</p>
                  <p>{currentProduct?.data?.attributes?.product_code} </p>
                </div>
              )}
              {currentProduct?.data?.attributes?.brand && (
                <div className="productSpecs__specs-item">
                  <p>Brand</p>
                  <p>{currentProduct?.data?.attributes?.brand} </p>
                </div>
              )}
              {currentProduct?.data?.attributes?.colour && (
                <div className="productSpecs__specs-item">
                  <p>Colour</p>
                  <p>{currentProduct?.data?.attributes?.colour} </p>
                </div>
              )}
              {currentProduct?.data?.attributes?.material && (
                <div className="productSpecs__specs-item">
                  <p>Material</p>
                  <p>{currentProduct?.data?.attributes?.material}</p>
                </div>
              )}
              {currentProduct?.data?.attributes?.package_height && (
                <div className="productSpecs__specs-item">
                  <p>Height</p>
                  <p>{currentProduct?.data?.attributes?.package_height} mm</p>
                </div>
              )}
              {currentProduct?.data?.attributes?.package_length && (
                <div className="productSpecs__specs-item">
                  <p>Length</p>
                  <p>{currentProduct?.data?.attributes?.package_length} mm</p>
                </div>
              )}
              {currentProduct?.data?.attributes?.package_width && (
                <div className="productSpecs__specs-item">
                  <p>Width</p>
                  <p>{currentProduct?.data?.attributes?.package_width} mm</p>
                </div>
              )}
              {currentProduct?.data?.attributes?.package_weight && (
                <div className="productSpecs__specs-item">
                  <p>Weight</p>
                  <p>
                    {currentProduct?.data?.attributes?.package_weight}{" "}
                    {currentProduct?.data?.attributes?.weight_unit}
                  </p>
                </div>
              )}
            </div>
          )}
          {currentTab === "reviews" && (
            <div className="reviews">
              {currentProduct?.data?.attributes?.reviews?.reviews.length > 0 ? (
                currentProduct?.data?.attributes?.reviews.reviews.map(
                  (review) => <ReviewsItem key={review.id} review={review} />
                )
              ) : (
                <p>No reviews available</p>
              )}
            </div>
          )}
        </div>
      </section>
      <section className="relatedProducts">
        <div className="container">
          <ProductsList
            products={{ data: filterData }}
            title="Related Products"
            limit
          />
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
