import React from "react";
import "./HeroSlider.styles.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const HeroSlider = ({ title, imageSrc }) => {
  return (
    <>
      <div
        className="heroSlider"
        style={{ backgroundImage: `url('${imageSrc}')` }}
      >
        <h1>{title}</h1>
        <Link to={title} className="button-link">
          <Button primary>Shop Now</Button>
        </Link>
      </div>
    </>
  );
};

export default HeroSlider;
