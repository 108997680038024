import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import "./global.scss";
import NavigationRoutes from "./NavigationRoutes/NavigationRoutes";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { useEffect, useState } from "react";

function App() {
  const [products, setProducts] = useState([]);

  return (
    <>
      <Toaster position="top-left" />
      <ScrollToTop />
      <Navbar />
      <NavigationRoutes />
      <Footer />
    </>
  );
}

export default App;
