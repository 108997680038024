import React from "react";
import "./ReviewsItem.styles.scss";

const ReviewsItem = ({ review }) => {
  return (
    <div className="reviews">
      <div className="reviews-item">
        <div className="reviews-item-top">
          <div className="reviews-item-name-rating">
            <h3>{review.name}</h3>
            <p>⭐️ ⭐️ ⭐️ ⭐️ ⭐️</p>
          </div>
          <div className="reviews-item-date">
            <p>{review.date}</p>
          </div>
        </div>
        <div className="reviews-item-bottom">
          <p>{review.review}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewsItem;
