import React from "react";
import { Navigate, Route, Routes, ScrollRestoration } from "react-router-dom";
import AccountPage from "../Pages/AccountPage/AccountPage";
import AdvantageOfNaturalStonePage from "../Pages/AdvantageOfNaturalStonePage/AdvantageOfNaturalStonePage";
import CaringForStone from "../Pages/CaringForStone/CaringForStone";
import CartPage from "../Pages/Cart/CartPage";
import CheckoutPage from "../Pages/CheckoutPage/CheckoutPage";
import ContactPage from "../Pages/ContactPage/ContactPage";
import HomePage from "../Pages/HomePage/HomePage";
import InstallationPrinciples from "../Pages/InstallationPrinciples/InstallationPrinciples";
import LoginPage from "../Pages/LoginPage/LoginPage";
import NaturalStoveVsCeramicTiles from "../Pages/NaturalStoveVsCeramicTiles/NaturalStoveVsCeramicTiles";
import SignupPage from "../Pages/SignupPage/SignupPage";
import TermsPage from "../Pages/TermsPage/TermsPage";
import Account from "../components/Account/Account";
import Orders from "../components/Orders/Orders";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import ProductsPage from "../Pages/ProductsPage/ProductsPage";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import MiniCart from "../components/MiniCart/MiniCart";

import OrderDetails from "../components/OrderDetails/OrderDetails";
import Invoice from "../components/Invoice/Invoice";
import ProtectedRoutes from "./ProtectedRoutes";
import Delivery from "../components/Delivery/Delivery";

const NavigationRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route
          path="/advantages-of-natural-stone"
          element={<AdvantageOfNaturalStonePage />}
        />
        <Route
          path="/natural-stone-vs-ceramic-tile"
          element={<NaturalStoveVsCeramicTiles />}
        />
        <Route
          path="/installation-principles"
          element={<InstallationPrinciples />}
        />
        <Route path="/caring-for-stone" element={<CaringForStone />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/checkout/invoice/:id" element={<Invoice />} />

        {/* Add a more specific route for product details */}
        <Route path="/product/:productName/:id" element={<ProductDetails />} />

        <Route path="/:category" element={<ProductsPage />} />
        <Route path="/:category/:subcategory" element={<ProductsPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/my-account" element={<AccountPage />}>
            <Route index element={<Navigate replace to="account-details" />} />
            <Route path="account-details" element={<Account />} />
            <Route path="orders" element={<Orders />} />
            <Route path="orders/:id" element={<OrderDetails />} />
          </Route>
        </Route>

        <Route path="/terms-and-conditions" element={<TermsPage />} />

        <Route path="*" element={<p>Path does not exist</p>} />
      </Routes>
    </>
  );
};

export default NavigationRoutes;
