import React from "react";
import "./NaturalStoveVsCeramicTiles.styles.scss";

const NaturalStoveVsCeramicTiles = () => {
  return (
    <section className="naturalStones">
      <div className="container">
        <div className="naturalStones__image">
          <img src="assets/images/natural-vs-ceramic.png" />
        </div>
        <div className="naturalStones__content">
          <h3>Natural Stone vs. Ceramic Tile</h3>
          <div className="text-box">
            <h3>Natural Characteristics</h3>
            <p>
              Understand that many natural stones may contain small
              naturally-occurring cracks (fissures), granite countertops may
              have some pitting and each stone has its own unique
              characteristics. Cracking and pitting are common complaints heard
              from consumers who were unaware of these characteristics when they
              selected natural stone. A common saying in the stone business:
            </p>
          </div>
          <div className="text-box">
            <h3>Natural Characteristics</h3>
            <p>
              Understand that many natural stones may contain small
              naturally-occurring cracks (fissures), granite countertops may
              have some pitting and each stone has its own unique
              characteristics. Cracking and pitting are common complaints heard
              from consumers who were unaware of these characteristics when they
              selected natural stone. A common saying in the stone business:
            </p>
          </div>
          <div className="text-box">
            <h3>Natural Characteristics</h3>
            <p>
              Understand that many natural stones may contain small
              naturally-occurring cracks (fissures), granite countertops may
              have some pitting and each stone has its own unique
              characteristics. Cracking and pitting are common complaints heard
              from consumers who were unaware of these characteristics when they
              selected natural stone. A common saying in the stone business:
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NaturalStoveVsCeramicTiles;
