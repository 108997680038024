import React from "react";
import "./QuantityButtons.styles.scss";

const QuantityButtons = () => {
  return (
    <div className="QuantityButtons">
      <span>-</span>
      <span>1</span>
      <span>+</span>
    </div>
  );
};

export default QuantityButtons;
